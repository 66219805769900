import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import FundRequestViewCards from "./FundRequestViewCards";
import FundRequestCoinsList from "./FundRequestCoinsList";
import AddCoinModal from "./AddCoinModal";
import { useState } from "react";

export default function FundRequestView() {
  const navigate = useNavigate();
  const [showAddCoinModal, setShowAddCoinModal] = useState(false)
  const handleNavigateBack = () => {
    navigate(-1);
  };
  const handleAddCoin = () => {
    setShowAddCoinModal(true)
  }


  return (
    <div className="pt-[2rem]">
      <div className="flex justify-between items-center mx-5 flex-wrap md:gap-0 gap-[1rem]">
        <button
          onClick={handleNavigateBack}
          className="flex items-center justify-center gap-3 bg-primary text-white pr-4 pl-3 py-2 rounded-md"
        >
          <Icon icon="ion:chevron-back-outline" width="1.2em" height="1.2em" />
          <span> Back</span>
        </button>
        <button onClick={handleAddCoin} className="bg-[#25BF17] rounded-[8px] px-[31px] py-[8px] text-white flex items-center gap-3">
          Add Coins
          <Icon icon="akar-icons:arrow-right" />
        </button>
      </div>

      <div className="ml-[1.3rem] border-[1px] border-gray-300 max-w-[550px] p-5 rounded-md mt-[3rem] shadow-3xl">
        <div className="flex items-center justify-between flex-wrap md:gap-0 gap-[1rem]">
          <div className="flex gap-5">
            <Icon className="bg-[#49e4222d] text-[#46be42] rounded-md mt-1" icon="mdi:user" width="2em" height="2em" />
            <div>
              <h3 className="font-semibold text-[1.25rem]">Billal Hossain</h3>
              <p><span className="text-[#8B8D97] text-[0.875rem]">Created on</span> 12 Sept 2024</p>
            </div>
          </div>
          <button className="bg-[#49e4221e] flex items-center gap-3 px-5 py-1 rounded-md">
            <div className="w-[10px] h-[10px] rounded-full bg-[#298b39]"></div>
            <span className="text-[#37aa37]">Active</span>
          </button>
        </div>

        <div className="pt-[3rem] flex items-center justify-between flex-wrap md:gap-0 gap-[2rem]">
          <div>
            <p className="text-[#8B8D97] text-[0.875rem]">Email ID</p>
            <h3 className="font-medium">billalhossain@gmail.com</h3>
          </div>
          <div>
            <p className="text-[#8B8D97] text-[0.875rem]">Mobile Number</p>
            <h3 className="font-medium">+88017xxxxxxxx</h3>
          </div>
          <div>
            <p className="text-[#8B8D97] text-[0.875rem]">Country</p>
            <div className="font-medium flex items-center gap-1">
              <Icon icon="emojione-v1:flag-for-india" width="1.2em" height="1.2em" />
              <p>India</p>
              <p className="text-gray-500">|</p>
              <p>INR</p>
            </div>
          </div>
        </div>
      </div>

      <div className='lg:m-5 md:mt-30 sm:mt-20 m-3'>
        <h3 className="text-[20px] font-medium text-[#45464E] mb-[32px] mt-[3rem]">Fund Requests</h3>
        <FundRequestViewCards />
        <FundRequestCoinsList />
      </div>
      {showAddCoinModal && <AddCoinModal showAddCoinModal={showAddCoinModal} setShowAddCoinModal={setShowAddCoinModal} />}
    </div>
  );
}
