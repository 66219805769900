import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Icon } from "@iconify/react";
import "flatpickr/dist/themes/material_green.css";
import {
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import CustomCheckbox from "../../../helpers/CustomCheckbox";
import { Link } from "react-router-dom";

//Fake Data
const fundRequestsData = [
  {
    id:"1",
    userName: "John Doe",
    requestId: "A1B2C3D4E5F6G7H8I9J0",
    countryAndCurrency: "India | INR",
    dateOfRequest: "2024-01-23",
    requestAmount: "1000",
    paymentStatus: "Success",
  },
  {
    id:"2",
    userName: "Jane Smith",
    requestId: "K1L2M3N4O5P6Q7R8S9T0",
    countryAndCurrency: "India | INR",
    dateOfRequest: "2024-01-24",
    requestAmount: "750",
    paymentStatus: "Pending",
  },
  {
    id:"3",
    userName: "Bob Johnson",
    requestId: "U1V2W3X4Y5Z6A7B8C9D0",
    countryAndCurrency: "India | INR",
    dateOfRequest: "2024-02-01",
    requestAmount: "1200",
    paymentStatus: "Success",
  },
  {
    id:"4",
    userName: "Alice White",
    requestId: "E1F2G3H4I5J6K7L8M9N0",
    countryAndCurrency: "India | INR",
    dateOfRequest: "2024-02-15",
    requestAmount: "900",
    paymentStatus: "Hold",
  },


];

// Table columns
const columns = [
  {
    name: "SL",
    selector: (row, index) => index + 1,
    center: true,
    maxWidth: "0.5rem",
  },
  {
    name: "Ticket ID",
    id: "description",
    selector: (row) => row.requestId,
    sortable:true,
    center: true,
    grow: 2,
    icon: "solar:sort-outline",
  },
  {
    name: "Date of Requesting",
    id: "createdAt",
    selector: (row) => row.dateOfRequest,
    sortable:true,
    center: true,
    grow: 2,
    icon: "solar:sort-outline",
  },
  {
    name: "Coins Requested",
    id: "createdAt",
    selector: (row) => row.requestAmount,
    sortable:true,
    center: true,
    grow: 2,
    icon: "solar:sort-outline",
  },
  {
    name: "Status",
    id: "image",
    sortable:true,
    selector: (row) => (
      <p className={`rounded-[10px] py-2 px-4 flex font-semibold items-center gap-2 ${row.paymentStatus?.toLowerCase()==="success" ? 'bg-[#25BF1733] text-[#25BF17]' : row.paymentStatus?.toLowerCase()==="pending" ? 'bg-[#FFCE0633] text-[#FFCE06]' : 'bg-[#FF002333] text-[#FF0023]'}`}>
        <span className={`w-2 h-2 rounded-full block ${row.paymentStatus?.toLowerCase() === "success" ? 'bg-[#25BF17]' : row.paymentStatus?.toLowerCase() === "pending" ? 'bg-[#FFCE06]' : 'bg-[#FF0023]'}`}></span>
        <span>{row.paymentStatus}</span>
      </p>
    ),
    center: true,
    grow: 1,
    icon: "solar:sort-outline",
  },
  {
    name: "Action",
    id: "action",
    cell: (row) => (
      <div className="flex space-x-2">
        <Link to={`/fund-requests/view/${row.id}`}>
        <IconButton
          className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
          style={{
            borderRadius: "5.55px",
            border: "0.925px solid #D9D9D9",
            background: "#FFF",
          }}
        >
          <Icon icon="mdi:eye" className="text-[1.5rem]" />
        </IconButton>
        </Link>
        {/* {row.paymentStatus?.toLowerCase() === "pending" 
        && 
        <>
        <IconButton
          className="shadow-none hover:shadow-none focus:shadow-none text-[#25BF17] bg-[#E8F7E6]"
          style={{
            borderRadius: "6px",
            border: "1px solid #25BF17",
          }}
        >
        <Icon icon="mdi:tick" className="text-[1.5rem]" />
        </IconButton>
        <RejectModal></RejectModal>

        </>} */}
      </div>
    ),
    center: true,
    grow: 1,
  },
];

export default function FundRequestCoinsList() {
  const [searchKey, setSearchKey] = useState("");

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = fundRequestsData.filter(
    (item) =>
      item.userName &&
      item.userName.toLowerCase().includes(searchKey.toLowerCase())
  );

  const handleClear = () => {
    if (searchKey) {
      setSearchKey("");
      setResetPaginationToggle(!resetPaginationToggle);
    }
  };

  const handleInputChange = (e) => {
    setSearchKey(e.target.value);
  };

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        padding: "10px 5px",
        color: "#212529",
      },
    },
    head: {
      style: {
        borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        fontSize: "15px",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#565656",
        padding: "10px 5px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    pagination: {
      style: {
        boxShadow: "10px 5px 5px #ddd",
        marginBottom: "5px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1000px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };

  return (
    <div className="py-[1rem] w-auto mb-10">
      {/* visible till Medium screen */}
      <div
        className="md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[full] my-[1rem]"
        style={{
          border: "0.6px solid #DFDFDF",
          borderRadius: "6px",
        }}
      >
        <div className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md ">
          <Icon
            icon="iconamoon:search-bold"
            className="text-[#272727] max-sm:text-[0.9rem]"
          />
        </div>
      </div>

      <div className="flex flex-col max-xs:items-start xs:flex-row gap-[0.5rem] sm:gap-[1rem] justify-center sm:justify-between items-center px-[1rem] py-[0.5rem] rounded-md mb-[1rem]">
        {/* Visible from Medium Screen */}
        <h1 className="text-[16px] font-medium text-[#45464E]">List</h1>
        <div className="flex gap-[2rem] items-center">
          <div
            className="max-md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[300px] lg:w-[350px]"
            style={{
              border: "0.6px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <input
              type="text"
              className="flex-1 bg-transparent  placeholder-[#848484] outline-none "
              placeholder="Search here"
              value={searchKey}
              onChange={handleInputChange}
            />
            <button
              onClick={handleClear}
              className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md"
            >
              <Icon icon="akar-icons:cross" className="text-white" />
            </button>
          </div>

          {/* Buttons with icons  hidden from lg*/}
          <div className="flex items-center gap-4">
            <button className="flex items-center gap-2  p-[4px] rounded-[4px] border-[#858585] border-[1px] text-[#858585] w-[80px] h-[29px] text-[11px]">
              <Icon icon="mingcute:filter-line" className="w-[12px] h-[13px]"/>
              Filter
            </button>
            <button className="flex items-center gap-2  p-[4px] rounded-[4px] border-[#858585] border-[1px] text-[#858585] w-[80px] h-[29px] text-[11px]">
              <Icon icon="solar:calendar-linear" className="w-[12px] h-[13px]"/>
              Date
            </button>
            <select className="p-[4px] rounded-[4px] border-[#858585] border-[1px] text-[#858585] w-[80px] h-[29px] text-[11px]">
               <option value="country">country</option>
            </select>
          </div>

          <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center py-[0.2rem]">
            <div className="">
              <Menu>
                <MenuHandler>
                  <button className="text-[0.8rem] sm:text-[1rem] flex items-center justify-center max-xs:w-full gap-[0.3rem] xs:gap-[0.5rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md">
                    <Icon
                      icon="material-symbols:download"
                      className="text-[1.2rem] sm:text-[1.5rem]"
                    />
                    <span className="whitespace-nowrap">Export</span>
                  </button>
                </MenuHandler>
                <MenuList>
                  <MenuItem
                    //  onClick={downloadPdf}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                  >
                    {" "}
                    <Icon
                      icon="bxs:file-pdf"
                      className="text-[#BE0A23] w-[1.6rem] h-auto"
                    />
                    Download as PDF
                  </MenuItem>
                  <hr className="my-[0.2rem] sm:my-[0.5rem]" />
                  <MenuItem
                    //  onClick={downloadCSV}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.9rem]"
                  >
                    <Icon
                      icon="fa-solid:file-csv"
                      className="text-[#29672E] w-[1.1rem] h-auto"
                    />
                    Download as CSV
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <DataTable
          columns={columns.map((column) => ({
            ...column,
            name: (
              <div className="flex items-center justify-between">
                <span>{column.name}</span>
                {column.icon && (
                  <Icon icon={column.icon} className="text-gray-600 ml-1" />
                )}
              </div>
            ),
          }))}
          data={filteredItems}
          customStyles={customStyles}
          pagination
          selectableRows
          selectableRowsComponent={CustomCheckbox}
        />
      </div>
    </div>
  );
}
