import { RouterProvider } from "react-router-dom";
import { router } from "./routes/Route";
import { QueryClientProvider, QueryClient } from "react-query";
import { AuthContextProvider } from "./store/AuthContext";
import { ToastContainer } from 'react-toastify';
function App() {

   const queryClient = new QueryClient();
   return (
      <AuthContextProvider>

         <QueryClientProvider client={queryClient}>
            <div className="font-inter">
               <ToastContainer />
               <RouterProvider router={router} />
            </div>

         </QueryClientProvider>
      </AuthContextProvider>


   );
}

export default App;
