export let baseUrl = "http://localhost:4000";



// const type = "DEV";
const type = "PROD";

if (type === "PROD") {
    baseUrl = "https://api.strikexgaming.com"
}
else {
    baseUrl = "http://localhost:4000"
}


export const APIurls = {
    getRoles: `${baseUrl}/api/v2/get-roles`,
    fetchWalletAgentDetails: `${baseUrl}/wallet-agent/fetch/agent`,
    
    fetchFundRequests: `${baseUrl}/wallet-agent/fetch/fund-request`,
    updateFundRequestStatus: `${baseUrl}/wallet-agent/update/fund-request`,
    updateFundRequestWithCoins: `${baseUrl}/wallet-agent/update/fund-request-with-coin`,
    fetchFundRequestById: `${baseUrl}/wallet-agent/fetch/fund-request`,

    fetchDepositRequests: `${baseUrl}/wallet-agent/all/deposit-request`,
    createDepositRequest: `${baseUrl}/wallet-agent/create/deposit-request`,
    fetchBannerDetails: `${baseUrl}/wallet-agent/fetch/banners`,


    // fetchLottary: `${baseUrl}/api/v2/fetch-lottary`,
    // createLottary: `${baseUrl}/api/v2/create-lottary`,
    // fetchLottaryById: `${baseUrl}/api/v2/single-lottary`,
    // editLottary: `${baseUrl}/api/v2/edit-lottary`,
    // deleteLottery: `${baseUrl}/api/v2/archive-lottary`,

    // fetchUsers: `${baseUrl}/api/v2/fetch-users`,
    // fetchUserById: `${baseUrl}/api/v2/fetch-single-user`,
    // disableUser: `${baseUrl}/api/v2/disable-users`,

    // createAgent: `${baseUrl}/api/v2/create-agent`,
    // fetchAgent: `${baseUrl}/api/v2/fetch-all-agents-private`,
    // fetchAgentById: `${baseUrl}/api/v2/fetch-agent-details`,
    // disableAgent: `${baseUrl}/api/v2/disable-agents`,

    // fetchPrivateLottary: `${baseUrl}/api/v2/fetch-agents-private-lottary`,
    // fetchSinglePrivateLottary: `${baseUrl}/api/v2/single-lottary-private`,
    // createPrivateLottary: `${baseUrl}/api/v2//make-private-lottary`,
    // editPrivateLottary: `${baseUrl}/api/v2/edit-private-lottary`,
    // deletePrivateLottary: `${baseUrl}/api/v2/archive-private-lottary`,

    // inviteUsers: `${baseUrl}/api/v2/create-invite`,
    // crateSubAdmin: `${baseUrl}/api/v2/create-subadmin`,
}