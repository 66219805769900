import React from 'react'

export default function Settings() {
  return (
    <div className='lg:m-5 md:mt-30 sm:mt-20 m-3'>
        <h3>Agent Panel Settings</h3>
    </div>
  )
}

