import React, { useEffect, useState } from "react";
import { IconButton } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import { toast } from "react-toastify";
import { RotatingLines, ThreeDots } from 'react-loader-spinner'
import NumberInput from "../../../helpers/NumberInput";
import { useQuery } from "react-query";
import moment from "moment";

export default function RejectRequestModal({ id, setShowProcessModal, setIsRefetch, amountRequested, ticketId }) {
  const [showError, setShowError] = useState("");
  const [loaderBtn, setLoaderBtn] = useState(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const { getAccessToken } = useAuth();
  const { fetchAgentDetails } = useAuth();
  const [requestData, setRequestData] = useState();
  const [coins, setCoins] = useState("");
  const [fetchLoaderBtn, setFetchLoaderBtn] = useState(false);

  const fetchFundRequestDataById = async () => {
    setFetchLoaderBtn(true);
    const token = await getAccessToken()
    try {
      const response = await fetch(`${APIurls.fetchFundRequestById}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        throw new Error("Failed to Fetch Fund Request Data");
      }
      const result = await response.json();
      console.log("Fund Request Data By Id", result?.response?.request);
      setCoins(result?.response?.request?.amountRequested);
      setRequestData(result?.response?.request)
    } catch (error) {
      console.log("Failed to Fetch Fund Request Data ===>", error);
    }
    setFetchLoaderBtn(false);
  };

  useEffect(() => {
    fetchFundRequestDataById();
  }, [])

  const submitHandler = async () => {
    if (!coins || coins < 100) {
      setShowError(true);
      return;
    }
    setDisableSubmitBtn(true);
    setLoaderBtn(true);
    const token = await getAccessToken();
    try {
      const response = await fetch(`${APIurls.updateFundRequestWithCoins}/${id}`, {
        method: "PUT",
        body: JSON.stringify({
          "coin": coins,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
      );
      if (!response.ok) {
        if (response.statusCode === 400) {
          const errorResult = await response.json();
          toast.error(errorResult);
          throw new Error(errorResult);
        } else {
          toast.error("Failed to Add Coins");
          throw new Error("Failed to Add Coins");
        }
      }
      const result = await response.json();
      toast.success("Coins Added successfully");
      setShowProcessModal(false);
      setIsRefetch(true);
      fetchAgentDetails(token);
      console.log("Coins Added -->", result);
    } catch (error) {
      console.warn("Coins Added Error -->", error);
    }
    setLoaderBtn(false);
    setDisableSubmitBtn(false);
  };

  console.log("Coins", coins);

  return (
    <div className="flex items-center justify-center absolute bg-[#000000E6] h-full w-full inset-0 z-10">
      <div className="flex flex-col bg-white py-6 px-8 min-w-[60%] max-w-[75%] rounded-2xl">
        <div className="flex justify-between items-center">
          <h3 className="font-semibold text-[1.75rem]">
            Request Details
          </h3>
          <IconButton
            onClick={() => setShowProcessModal(false)}
            className="shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-md hover:bg-[#FF0023] hover:text-white"
            size="sm"
          >
            <Icon icon="material-symbols:close" className="text-[1.5rem]" />
          </IconButton>
        </div>
        {
          fetchLoaderBtn ?
            (
              <div className='flex justify-center items-center h-screen'>
                <RotatingLines
                  strokeColor="green"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="150"
                  visible={true}
                />
              </div>
            ) : (
              <div className="mt-7">
                <div className="grid lg:grid-cols-3 grid-cols-2 gap-[1.5rem]">
                  <div>
                    <p className="text-gray-600 mb-1">User Name</p>
                    <h5 className="font-medium text-[1.2rem]">
                      {requestData?.UserID?.FirstName + " " + requestData?.UserID?.LastName}
                    </h5>
                  </div>
                  <div>
                    <p className="text-gray-600 mb-1">Ticket ID</p>
                    <h5 className="font-medium text-[1.2rem]">
                      {requestData?.ticket_id}
                    </h5>
                  </div>
                  <div>
                    <p className="text-gray-600 mb-1">Payment Method</p>
                    <h5 className="font-medium text-[1.2rem]">
                      {requestData?.paymentMethod}
                    </h5>
                  </div>
                  <div>
                    <p className="text-gray-600 mb-1">UTR Number</p>
                    <h5 className="font-medium text-[1.2rem]">
                      {requestData?.UTR_no}
                    </h5>
                  </div>
                  <div>
                    <p className="text-gray-600 mb-1">Coins Requested</p>
                    <h5 className="font-medium text-[1.2rem]">
                      {requestData?.amountRequested}
                    </h5>
                  </div>
                  <div>
                    <p className="text-gray-600 mb-1">Payment Proof</p>
                    <h5 className="font-medium text-[1.2rem]">
                      <a href={requestData?.Payment_Image} target="_blank" rel="noreferrer" className="text-blue-500 hover:border-b-2 hover:border-b-blue-600">
                        Payment_Document
                      </a>
                    </h5>
                  </div>
                  <div>
                    <p className="text-gray-600 mb-1">Date Of Requesting</p>
                    <h5 className="font-medium text-[1.2rem]">
                      {moment(requestData?.createdAt).format("DD/MM/YYYY")}
                    </h5>
                  </div>
                  <div className="md:col-span-3">
                    <p className="text-gray-600 mb-1">Remarks</p>
                    <h5 className="font-medium text-[1.1rem]">
                      Note: Pay to the provided bank details, submit proof to the
                      agent, and receive coins in your wallet upon verification.
                    </h5>
                  </div>
                  <div className="md:col-span-3 flex justify-between items-end mt-2 mb-4">
                    <div>
                      <div className="flex flex-col gap-[0.5rem]">
                        <div className="flex flex-col gap-[0.5rem]">
                            <label htmlFor="amount" className="text-gray-600">
                              Enter/Update Coins<span className="text-[#E92215]">*</span>
                            </label>
                            <NumberInput
                              className="text-[1.7rem] font-semibold placeholder:text-[#7F7F7F] placeholder:font-normal outline-none border-2 border-[#D9D9D9] py-[0.2rem] px-[1rem] rounded-[8px] w-[300px]"
                              type="number"
                              name="amount"
                              id="amount"
                              placeholder="1000"
                              value={coins}
                              onChange={(e) => setCoins(e.target.value)}
                            />
                          {showError && !coins && (
                            <p className="text-[#E92215] text-sm">*This field is required</p>
                          )}
                          {showError && coins < 100 && coins && (
                            <p className="text-[#E92215] text-sm">*Adding Coins should be greater than or equal to 100</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-end">
                      {
                        loaderBtn ? (
                          <ThreeDots
                            height="50"
                            width="50"
                            radius="9"
                            color="#24B24B"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        ) : (
                          <button
                            disabled={disableSubmitBtn}
                            type="button"
                            className="text-center justify-center text-[0.8rem] sm:text-[18px] flex items-center max-xs:w-full gap-[0.3rem] xs:gap-[1rem] bg-primary text-white py-[0.6rem] px-[3rem] xs:px-[3.5rem] font-semibold rounded-[8px]"
                            onClick={submitHandler}
                          >
                            Add Coins
                          </button>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            )
        }
      </div>
    </div>
  );
}



