import Header from "./Header";
import Notifications from "./Notifications";

export default function NotificationsWrapper() {
  return (
    <div className='p-[2.5rem] bg-white'>
        <Header/>
        <Notifications/>
    </div>
  )
}

