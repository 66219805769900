import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import authBgImage from '../assets/auth-bg-img.svg'
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import strikeLogo from "../assets/strike-logo.svg"
import { Icon } from '@iconify/react';
import { useAuth } from '../store/AuthContext';
import 'react-toastify/dist/ReactToastify.css';
import { handleFirebaseError } from '../utils/firebasehandle'



export default function AgentLogin() {
  const [showPassword, setShowPassword] = useState(false);
  // const [forgotPass, setForgotPass] = useState(false);
  const { register, handleSubmit, formState: { errors }, control, reset } = useForm();
  const [loaderBtn, setLoaderBtn] = useState(false);
  const navigate = useNavigate();

  const { login } = useAuth()

  const onSubmit = async (data) => {
    console.log(data);

    const email = data?.email
    const password = data?.password
    setLoaderBtn(true)
    await login(email, password).then((e) => {
      setLoaderBtn(false)
      toast.success("Login Success !")
      navigate("/")

    }).catch(err => {
      setLoaderBtn(false)
      console.log(err.code);
      toast.error(handleFirebaseError(err))

    })

  }

  const showUserPassword = (e) => {
    setShowPassword(!showPassword);
  };
  console.log("ERROR:", errors);

  return (
    <div
      className="w-full min-h-screen max-xs:px-[0.5rem] bg-cover bg-[#F1F6FA] bg-center bg-repeat flex justify-center items-center"
      style={{ backgroundImage: `url(${authBgImage})` }}
    >
      <div className="bg-white flex flex-col min-w-[20rem] xs:min-w-[28.75rem] justify-center  px-[1rem]  xs:px-[4rem] py-[1rem] xs:py-[3rem] gap-[1rem] xs:gap-[1.8rem] rounded-[10px]  ">
        <Link to="/admin">
          <div>
            <img
              src={strikeLogo}
              alt=""
              className=""
            />
          </div>
        </Link>
        <h1 className="text-[#1A3032] font-bold text-[1.5rem] xs:text-[1.8rem]">
          Login here
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex flex-col gap-[1rem]"
        >
          <div className="group flex flex-col gap-[0.5rem]">
            <label htmlFor="email" className="text-[1rem] font-[600]">
              Email Address
            </label>
            <div className={`flex flex-row text-[0.9rem] font-[600] group-focus-within:bg-white  group-focus-within:shadow-md border  ${!errors.email ? "border-[#AFBACA]" : "border-red-600 "
              } items-center rounded-md px-[0.5rem] xs:px-[1rem] py-[0.5rem]`}>
              <div className="">
                <Icon icon="ic:round-mail" className="text-[1.2rem]" />
              </div>

              <input
                type="text"
                name="email"
                placeholder="example@gmail.com"
                className="ml-[1rem] outline-none w-1 border-none flex-grow placeholder:text-[#858585]"
                {...register("email", {
                  required: "*This field is required.",
                  pattern: /^\S+@\S+$/i,
                })}
              />
            </div>
            {errors.email?.type === "required" && (
              <p className="text-red-600 text-sm">{errors.email.message}</p>
            )}
            {errors.email?.type === "pattern" && (
              <p className="text-sm text-red-600">Invalid email</p>
            )}

          </div>
          <div className="group flex flex-col gap-[0.5rem]">
            <label htmlFor="password" className="text-[1rem] font-[600]">
              Password
            </label>
            <div className={`flex flex-row text-[0.9rem] font-[600] group-focus-within:bg-white  group-focus-within:shadow-md border  ${!errors.password ? "border-[#AFBACA]" : "border-red-600 "
              } items-center rounded-md px-[0.5rem] xs:px-[1rem] py-[0.5rem]`}>
              <div className="">
                <Icon icon="mdi:password" className="text-[1.2rem]" />
              </div>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="*********"
                className="mx-[1rem] outline-none border-none w-1 flex-grow placeholder:text-[#858585]"
                {...register("password", {
                  required: "*This field is required.",
                  minLength: 6,
                })}
              />
              <div className="text-[1.2rem] cursor-pointer ">
                {showPassword ? (
                  <Icon icon="fluent:eye-20-filled" onClick={showUserPassword} />
                ) : (
                  <Icon icon="fluent:eye-off-20-filled" onClick={showUserPassword} />
                )}
              </div>
            </div>
            {errors.password?.type === "required" && (
              <p className="text-red-600 text-sm">{errors.password?.message}</p>
            )}
            {errors.password?.type === "minLength" && (
              <p className="text-red-600 text-sm">*Minimum Password Length: 6</p>
            )}
            <div className="flex flex-row justify-end text-[#392C64] text-[0.8rem] font-[600]">
              <p
                className=" cursor-pointer"
                onClick={() => navigate("/forgot-Password")}
              >
                Forgot Password?
              </p>
            </div>
          </div>
          <div className="flex flex-row justify-center">
            {loaderBtn ? (
              <ThreeDots
                height="50"
                width="50"
                radius="9"
                color="#9FB947"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) :
              (<button
                type="submit"
                className="w-full text-white text-[1.1rem] rounded-md px-[1.5rem] py-[0.8rem] bg-primary font-bold"
              >
                Login
              </button>)}
          </div>
        </form>
        <ToastContainer />
      </div>
    </div>
  )
}
