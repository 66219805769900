import { Icon } from "@iconify/react/dist/iconify.js";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../store/AuthContext";
import { toast } from "react-toastify";
import { APIurls } from "../../../api/apiConstant";
import { Option, Select } from "@material-tailwind/react";
import { ThreeDots } from "react-loader-spinner";

export default function DepositDetails() {
  const [agentName, setAgentName] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [utrNumber, setUtrNumber] = useState("");
  const [remark, setRemark] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [showError, setShowError] = useState(false);
  const [loaderBtn, setLoaderBtn] = useState(false);
  const { state } = useLocation();
  const depositAmount = state?.depositAmount;
  console.log("DEPOSIT AMOUNT", depositAmount)
  const navigate = useNavigate();
  const { getAccessToken, agentDetails } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!((agentDetails?.FirstName + " " + agentDetails?.LastName) && paymentMethod && utrNumber && selectedFile)) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
    }

    const formData = new FormData();
    formData.append("agentName", agentDetails?.FirstName + " " + agentDetails?.LastName);
    formData.append("paymentMethod", paymentMethod);
    formData.append("utrNumber", utrNumber);
    formData.append("remark", remark);
    formData.append("paidAmount", depositAmount);
    formData.append("bonusPercentage", 20);
    formData.append("proofImg", selectedFile);

    const token = await getAccessToken();
    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };

    try {
      setLoaderBtn(true)
      const response = await fetch(APIurls.createDepositRequest, requestOptions);

      if (!response.ok) {
        setLoaderBtn(false)
        if (response.status === 400) {
          const result = await response.json();
          console.error("Error creating Deposit Request", result.message);
          console.log(result);
          toast.error(result?.message)
        } else {
          console.log(response.status)
          throw new Error("Error creating Deposit Request");
        }
      } else {
        setLoaderBtn(false)
        const result = await response.json();
        toast.success("Successfully Created Deposit Request!");
        console.log(result);
        setAgentName("");
        setPaymentMethod("");
        setUtrNumber("");
        setRemark("");
        setSelectedFile(null);
        navigate("/wallets");
      }
    } catch (error) {
      setLoaderBtn(false)
      toast.error("Error creating Deposit Request");
      console.warn(error);
    }
  }

  return (
    <div>
      <div className="bg-white p-3 my-2 rounded-lg">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center justify-center gap-3 bg-primary text-white pr-4 pl-3 py-2 rounded-md"
        >
          <Icon icon="ion:chevron-back-outline" width="1.2em" height="1.2em" />
          <span> Back</span>
        </button>
      </div>

      <div className="ml-[1rem] space-y-[2rem] bg-white rounded-lg p-[1.5rem]">
        <h3 className="text-[1.75rem] font-semibold">Deposit Details</h3>

        <div className="grid md:grid-cols-2 grid-cols-1 gap-[2rem]">
          <form className="space-y-[1.5rem]" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-2">
              <p className="text-gray-600">Agent Name</p>
              <input className="bg-[#D9D9D9] outline-none w-full p-2 text-[0.875rem] rounded-md text-black"
                type="text"
                // defaultValue="Glen Maxwell"
                placeholder="Enter Agent Name"
                value={agentDetails?.FirstName + " " + agentDetails?.LastName}
                // onChange={(e) => setAgentName(e.target.value)}
                readOnly
              />
              {showError && !(agentDetails?.FirstName + " " + agentDetails?.LastName) && (
                <p className="text-sm text-[#E92215]">
                  *This field is required.
                </p>
              )}
            </div>

            <div className="flex flex-col gap-2">
              <p className="text-gray-600">Payment Method<span className="text-[#FF0023] font-medium">*</span></p>
              <Select label="select"
                className="w-full rounded-md text-black label:text-red"
                value={paymentMethod}
                onChange={(value) => setPaymentMethod(value)}
              >
                <Option value="UPI">UPI</Option>
                <Option value="Bank Transfer">Bank Transfer</Option>
              </Select>
              {showError && !paymentMethod && (
                <p className="text-sm text-[#E92215]">
                  *This field is required.
                </p>
              )}
            </div>

            <div className="flex flex-col gap-2">
              <p className="text-gray-600">URT Number<span className="text-[#FF0023] font-medium">*</span></p>
              <input className="outline-none border-[1px] border-gray-300 shadow-sm w-full p-2 text-[0.875rem] rounded-md text-black"
                type="text"
                placeholder="Enter UTR Number"
                value={utrNumber}
                onChange={(e) => setUtrNumber(e.target.value)}
              />
              {showError && !utrNumber && (
                <p className="text-sm text-[#E92215]">
                  *This field is required.
                </p>
              )}
            </div>

            <div>
              <p className="text-gray-600">Remarks</p>
              <input className="outline-none border-[1px] border-gray-300 shadow-sm w-full p-2 text-[0.875rem] rounded-md text-black"
                type="text"
                placeholder="Add Remarks"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
              />
            </div>

            {/* Upload File  */}
            <div>
              {
                selectedFile ? (<div>
                  <p className="text-gray-600 mb-1">Upload Payment Proof<span className="text-[#FF0023] font-medium">*</span></p>
                  <div className="border-[1px] border-gray-300 rounded-md w-full h-[80px] flex  items-center gap-3 justify-center">
                    <p className="text-gray-500 text-[14px] text-center">{selectedFile.name}</p>
                    <Icon onClick={() => setSelectedFile(null)} className="cursor-pointer border-[1px] border-red-300 rounded-md text-red-500 hover:bg-red-800 hover:text-white" icon="bitcoin-icons:cross-filled" width="1.5em" height="1.5em" />
                  </div>
                </div>) : (<div>
                  <p className="text-gray-600 mb-1">Upload Payment Proof<span className="text-[#FF0023] font-medium">*</span></p>
                  <div className="border-[1px] cursor-pointer border-gray-300 rounded-md w-full h-[80px] flex flex-col items-center justify-center" onClick={() => document.getElementById('file').click()}>
                    <Icon className="text-gray-500" icon="icon-park-outline:upload-one" width="2em" height="2em" />
                    <p className="text-gray-500 text-[14px] text-center">Upload JPG, PNG, PDF. Size upto 20MB</p>
                    <input onChange={(e) => setSelectedFile(e.target.files[0])} id="file" className="outline-none border-[1px] border-gray-300 shadow-sm w-full px-2 py-1 rounded-md text-black hidden" type="file" placeholder="example agent" />
                  </div>
                </div>)
              }
              {showError && !selectedFile && (
                <p className="text-sm text-[#E92215]">
                  *This field is required.
                </p>
              )}
            </div>

            <div className="flex items-center justify-center">
              {loaderBtn ? (
                <ThreeDots
                  height="50"
                  width="50"
                  radius="9"
                  color="#9FB947"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) :
                (<button className="bg-primary text-white w-full h-[3rem] font-medium rounded-md"
                  type="submit">
                  Submit
                </button>)
              }
            </div>
          </form>

          <div className="space-y-[0.5rem] border-[1px] border-gray-300 rounded-lg shadow-3xl p-[0.8rem] max-h-[300px] mt-[1rem]">
            <h3 className="font-semibold text-[1.50rem]">Bank Account Details</h3>
            <div className="flex items-center gap-2">
              <span className="text-[1.25rem] font-medium">Bank Name:</span>
              <span className="font-semibold text-[1.50rem]">HDFC</span>
              <Icon className="cursor-pointer" icon="nimbus:copy" width="1.2em" height="1.2em" />
            </div>

            <div className="flex items-center gap-2">
              <span className="text-[1.25rem] font-medium">A/C Number:</span>
              <span className="font-semibold text-[1.50rem]">5644563653643</span>
              <Icon className="cursor-pointer" icon="nimbus:copy" width="1.2em" height="1.2em" />
            </div>

            <div className="flex items-center gap-2">
              <span className="text-[1.25rem] font-medium">IFSC Code:</span>
              <span className="font-semibold text-[1.50rem]">ABC5465436</span>
              <Icon className="cursor-pointer" icon="nimbus:copy" width="1.2em" height="1.2em" />
            </div>

            <div className="flex items-center gap-2">
              <span className="text-[1.25rem] font-medium">UPI ID:</span>
              <span className="font-semibold text-[1.50rem]">4536536546@YBL</span>
              <Icon className="cursor-pointer" icon="nimbus:copy" width="1.2em" height="1.2em" />
            </div>

            <p className="text-[0.875rem]">Note: Pay to the provided bank details, submit proof to the agent, and receive coins in your wallet upon verification.</p>
          </div>


        </div>
      </div>
    </div>
  );
}
