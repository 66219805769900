import TotalAmountLineChart from "./TotalAmountLineChart";
import TotalRequestPieChart from "./TotalRequestPieChart";

export default function LotterySummary() {
  return (
    <div>
        {/* <TotalAmountLineChart/> */}
        <TotalRequestPieChart/>
    </div>
  )
}
