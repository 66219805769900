import React from 'react'
import strikeLogo from '../assets/strike-logo.svg'
import suspendImg from '../assets/suspend.svg'

export default function Suspend() {
  return (
    <div className='flex flex-col gap-10'>
      <header className='h-[4.5rem] flex shadow-md fixed w-full z-50 py-2 px-16 bg-white'>
        <img src={strikeLogo} alt="logo" />
      </header>
      <div className='pt-[4.5rem] flex flex-col justify-center items-center text-center px-4'>
        <div className='flex items-center justify-center'>
          <img src={suspendImg} alt="suspend" className='h-[300px] sm:h-[400px]' />
        </div>
        <h1 className='text-[1.8rem] sm:text-[2rem] font-bold'>
          Account Suspended
        </h1>
        <p className='text-[1rem] sm:text-[1.1rem] font-normal mt-2'>
          For further assistance or to resolve this issue, please contact our support team or write an mail at <strong>support@strike.com</strong>.
        </p>
        <button className='text-[1rem] font-semibold bg-primary text-white py-3 xs:py-4 w-full xs:w-[400px] rounded-lg mt-12 mb-8'>
          Contact Support
        </button>
      </div>
    </div>
  )
}
