import Lotteries from './Lotteries'
import FundRequestList from './FundRequestList'
import { NavLink, Outlet } from 'react-router-dom'
import { Icon } from '@iconify/react/dist/iconify.js'
import { APIurls } from '../../../api/apiConstant';
import { useQuery } from 'react-query';
import { useAuth } from '../../../store/AuthContext';
import { useMemo } from 'react';

export default function FundRequestsWrapper() {

  const { getAccessToken } = useAuth();

  const fetchFundRequestData = async () => {
    const token = await getAccessToken()

    const response = await fetch(APIurls.fetchFundRequests, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error("Failed to Fetch Fund Requests");
    }
    const result = await response.json();

    console.log("Fund Requests...", result?.response?.fundRequest);
    return result?.response?.fundRequest?.reverse();
  };
  const { data: fundRequest, isLoading, refetch } = useQuery('fundRequestData', fetchFundRequestData);

  let fundRequestsData = useMemo(() => (
    fundRequest?.filter((data) => data?.status.toLowerCase() === "submitted" || data?.status.toLowerCase() === "hold")
  ), [fundRequest])

  console.log("fundRequestsData", fundRequestsData);

  return (
    <div className='lg:p-5 md:pt-30 sm:pt-20 p-3'>
      <h3 className="text-[20px] font-medium text-[#45464E] mb-[32px]">Fund Requests</h3>
      <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6 mb-[40px]">
        {/* badge -1: Total Users  */}
        <div className='py-3 px-4 rounded-lg shadow-md border-[1px] border-gray-300'>
          <header className='flex justify-between items-center'>
            <h3 className='text-[14px] text-[#858585]'>Total Requests</h3>
            <Icon className='text-[#25BF17] w-8 h-8' icon="mdi:user" />
          </header>
          <h3 className='text-[#25BF17] text-[20px] font-bold'>
            {fundRequest?.length}
          </h3>
        </div>

        {/* badge -2: Fund Added  */}
        {/* <div className='py-3 px-4 rounded-lg shadow-md border-[1px] border-gray-300'>
          <header className='flex justify-between items-center'>
            <h3 className='text-[14px] text-[#858585]'>Fund Added</h3>
          </header>

          <div className="flex justify-between  items-center mb-8">
            <h3 className='text-[#25BF17] text-[20px] font-bold'>₹420</h3>
            <div className="flex items-center gap-2">
              <Icon icon="twemoji:flag-india" />
              <span className="text-[11px]">INR</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                <path d="M9.25 4.5L6.25 7.5L3.25 4.5" stroke="#858585" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>

          <div className='my-3'><hr /></div>

          <footer className='flex justify-between items-center gap-1'>
            <div className='flex items-center gap-1'>
              <Icon className='text-[#198754] w-3 h-3' icon="mdi:arrow-up-thin" />
              <small className='text-[#198754] text-[10px]'>3.05%</small>
              <small className='text-[8px]'>Since last month</small>
            </div>
          </footer>
        </div> */}
      </div>
      <div className='flex gap-[4rem] border-b-2 border-[#989898] px-[1rem] pt-[1rem] mx-[0.5rem] mb-2'>
        <NavLink to={""} end>
          {({ isActive }) => (
            <h2 className={`text-[1.2rem] sm:text-[1.3rem] whitespace-nowrap pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
              Fund Requests
            </h2>
          )}
        </NavLink>
        <NavLink to={"total"}>
          {({ isActive }) => (
            <h2 className={`text-[1.2rem] sm:text-[1.3rem] whitespace-nowrap pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
              Total Funds
            </h2>
          )}
        </NavLink>
      </div>
      <Outlet context={[fundRequestsData, isLoading, refetch, fundRequest]}/>
    </div>
  )
}
