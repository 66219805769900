import React from 'react'
import { Outlet } from 'react-router-dom'

export default function AgentPanelFundRequest() {
  return (
    <div className='lg:p-5 md:pt-30 sm:pt-20 p-3 bg-white'>
        <Outlet></Outlet>
    </div>
  )
}
