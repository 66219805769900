import { Icon } from "@iconify/react/dist/iconify.js";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const amounts = [500, 1000, 2000, 5000]

export default function DepositRequest() {
  const [amount, setAmount] = useState(1000);

  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const handleBankAccountNavigation = ()=>{
    navigate('/wallets/deposit-details', { state: { depositAmount: amount } })
  }

  const handleCryptoNavigation = ()=>{
    alert('Crypto')
  }

  return (
    <div className="pt-[1rem]">
      {/* Back Button  */}
      <div className="bg-white p-3 my-2 rounded-lg">
      <button
        onClick={handleNavigateBack}
        className="flex items-center justify-center gap-3 bg-primary text-white pr-4 pl-3 py-2 rounded-md"
      >
        <Icon icon="ion:chevron-back-outline" width="1.2em" height="1.2em" />
        <span> Back</span>
      </button>
      </div>

      <div className="ml-[1rem] space-y-[2rem] bg-white rounded-lg p-[1.5rem] lg:w-[80%] md:w-[90%] w-100%">
        <h3 className="text-[1.75rem] font-semibold">Deposit Request</h3>

        <div>
          <h5 className="text-[#8B8D97] font-medium mb-1">Select Amount</h5>
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[1rem]">
            {
              amounts.map(item => ( <p onClick={()=>setAmount(item)} className={`border-[1px] text-[1.125rem] border-gray-300 rounded-md text-center font-semibold py-2 hover:bg-primary hover:text-white shadow-sm select-none ${item===amount ? 'bg-primary text-white' : ''}`}>{item}</p>))
            }
          </div>
        </div>

        <div>
          <h5 className="text-[#8B8D97] text-[0.875rem] font-medium mb-1">Enter Custom Amount</h5>
          <input
            className="border-[1px] border-gray-300 rounded-md font-bold text-[0.875rem] w-full outline-none p-2"
            value={amount}
            onChange={(e)=>setAmount(e.target.value)}
          />
          <p className="text-[#8B8D97]">You will get 20% More - i.e {(20*Number(amount))/100+Number(amount)}</p>
        </div>

        <div>
          <h5 className="text-[#8B8D97] font-medium mb-[0.5rem]">Select Payment Method</h5>
          <div className="grid grid-cols-2 gap-[1rem]">
            <button onClick={handleBankAccountNavigation} className="border-[1px] border-gray-300 rounded-md py-5 font-bold hover:bg-[#0A7C00] hover:text-white shadow-sm text-[1.5rem]">
              Bank Account
            </button>
            <button onClick={handleCryptoNavigation} className="border-[1px] border-gray-300 rounded-md py-5 font-bold bg-[#0A7C00] text-white shadow-sm text-[1.5rem]">
              Crypto
            </button>

          </div>
          <div className="flex items-center justify-end mt-[0.5rem] gap-1 font-semibold">
          <Icon className="text-gray-500" icon="clarity:exclamation-circle-solid" width="2em" height="2em" />
            <span>1000 Coins</span>
            <Icon
              icon="hugeicons:approximately-equal"
              width="1.2em"
              height="1.2em"
            />
            <Icon className="text-[#50AF95]" icon="simple-icons:tether" width="1.25em" height="1.25em" />
            <span>10.21</span>
          </div>
        </div>
      </div>
    </div>
  );
}
