import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/headers/Header";
import { motion, AnimatePresence } from "framer-motion"
import MobileHeader from "../components/headers/MobileHeader";
import { useDispatch, useSelector } from "react-redux";
import { hideNotificationModal } from "../store/notificationSlice";
import AgentSideBar from "../components/sidebars/AgentSideBar";


const AgentLayout = () => {
  const [isAdminSidebarOpen, setIsAdminSidebarOpen] = useState(false);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { showNotificationModal } = useSelector((store) => store.notification)

  const hideNotificationModalHandler = () => {
    if (showNotificationModal) {                // action dispatch only when nofication modal is showing
      dispatch(hideNotificationModal());
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [pathname])

  return (
    <div>

      <Header isAdminSidebarOpen={isAdminSidebarOpen}
        setIsAdminSidebarOpen={setIsAdminSidebarOpen} />
      <MobileHeader isAdminSidebarOpen={isAdminSidebarOpen}
        setIsAdminSidebarOpen={setIsAdminSidebarOpen} />

      <div className="flex overflow-y-hidden bg-white"
        onClick={hideNotificationModalHandler}
      >
        <div className="hidden lg:inline-block lg:flex-1">
        <AgentSideBar
            isAdminSidebarOpen={isAdminSidebarOpen}
            setIsAdminSidebarOpen={setIsAdminSidebarOpen}
        />
        </div>

        <AnimatePresence mode='wait'>
          {isAdminSidebarOpen && <motion.div className="absolute top-0 left-0 z-40 lg:hidden flex-1 "
            initial={{ x: -100 }} animate={{ x: 0 }} transition={{ type: "linear", duration: 0.5, }} exit={{ x: -400, transition: "linear", duration: 1.5, }} >
            <AgentSideBar
            isAdminSidebarOpen={isAdminSidebarOpen}
            setIsAdminSidebarOpen={setIsAdminSidebarOpen}
            />
          </motion.div>}
        </AnimatePresence>

        <div className="lg:flex-[5] p-5 w-full h-screen overflow-y-auto overflow-x-hidden lg:pt-[4.5rem] bg-white">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AgentLayout;