import { Icon } from '@iconify/react';


export default function FundRequestViewCards() {
  return (
    <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6 mb-[40px]">
       {/* badge -1: Total Users  */}
       <div className='py-3 px-4 rounded-lg shadow-md border-[1px] border-gray-300'>
        <header className='flex justify-between items-center'>
            <h3 className='text-[14px] text-[#858585]'>Total</h3>
        </header>

        <h3 className='text-[#25BF17] text-[20px] font-bold mb-5'>650</h3>

        <div className='my-3'><hr /></div>

        <footer className='flex justify-between items-center gap-1'>
        <div className='flex items-center gap-1'>
        <Icon className='text-[#198754] w-3 h-3' icon="mdi:arrow-up-thin" />
        <small className='text-[#198754] text-[10px]'>3.05%</small>
        <small className='text-[8px]'>Since last month</small>
        </div>
        </footer>
    </div>

      {/* badge -2: Fund Added  */}
      <div className='py-3 px-4 rounded-lg shadow-md border-[1px] border-gray-300'>
        <header className='flex justify-between items-center'>
            <h3 className='text-[14px] text-[#858585]'>Coins Added</h3>
        </header>

        <div className="flex justify-between  items-center mb-8">
        <h3 className='text-[#25BF17] text-[20px] font-bold'>₹420</h3>
        <div className="flex items-center gap-2">
        <Icon icon="twemoji:flag-india" />
        <span className="text-[11px]">INR</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
           <path d="M9.25 4.5L6.25 7.5L3.25 4.5" stroke="#858585" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        </div>
        </div>

        <div className='my-3'><hr /></div>

        <footer className='flex justify-between items-center gap-1'>
        <div className='flex items-center gap-1'>
        <Icon className='text-[#198754] w-3 h-3' icon="mdi:arrow-up-thin" />
        <small className='text-[#198754] text-[10px]'>3.05%</small>
        <small className='text-[8px]'>Since last month</small>
        </div>
        </footer>
      </div>
    </div>
  )
}
