import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";
import profilePhoto from "../../../assets/profile-photo.png";
import BankInformation from "./BankInformation";
import UPIForm from "./UPIForm";
import { useAuth } from "../../../store/AuthContext";

export default function Profile() {
  const { agentDetails } = useAuth();
  console.log("Agent Details===> ", agentDetails);
  const { FirstName, LastName, email, mobileNumber, location, createdAt, agentId } =
    agentDetails || {};
  const handleEditProfilePhoto = (e) => {
    e.preventDefault();
  };
  return (
    <div className="lg:pt-[2.5rem] md:pt-[5rem] md:pl-[2rem] bg-white space-y-20">
      {/* Personal Informations  */}
      <div>
        <h1 className="text-[1.25rem] font-medium mb-[2rem]">
          Personal Information
        </h1>
        <form className="md:mx-[3rem] border-[1px] border-gray-200 rounded-md p-5 shadow-2xl">
          <div className="flex md:flex-row flex-col md:items-center gap-[3rem]">
            <img
              className="md:w-[200px] w-full h-[200px]"
              src={profilePhoto}
              alt="Profile"
            />
            <div className="space-y-6">
              <h3 className="text-[1.25rem] font-bold text-[#25BF17]">
                Agent ID (123456)
              </h3>
              <button
                onClick={handleEditProfilePhoto}
                className="bg-[#25BF17] text-white font-medium md:px-6 px-2 py-3 flex items-center md:gap-3 gap-1 rounded-md"
              >
                <Icon className="text-[1.5rem]" icon="ic:round-edit" />
                <span>Edit Profile Picture</span>
              </button>
            </div>
          </div>

          {/* Input Fields  */}
          <div className="grid md:grid-cols-2 grid-cols-1 gap-x-16 gap-y-8 mt-[2rem]">
            <div>
              <label
                className="block text-[14px] w-full text-[#5E6366] mb-1"
                for="full-name"
              >
                Full Name
              </label>
              <input
                className="outline-none border-[1px] w-full border-[#D9D9D9] rounded-md py-2 px-4"
                id="full-name"
                type="text"
                placeholder="Enter Full Name"
                value={`${FirstName} ${LastName}`}
              />
            </div>

            <div>
              <label
                className="block text-[14px] w-full text-[#5E6366] mb-1"
                for="mobile-number"
              >
                Mobile Number
              </label>
              <input
                className="outline-none border-[1px] w-full border-[#D9D9D9] rounded-md py-2 px-4"
                id="mobile-number"
                type="text"
                placeholder="Enter Mobile Number"
                value={mobileNumber}
              />
            </div>

            <div>
              <label
                className="block text-[14px] w-full text-[#5E6366] mb-1"
                for="email-address"
              >
                Email Address
              </label>
              <input
                className="outline-none border-[1px] w-full border-[#D9D9D9] rounded-md py-2 px-4"
                id="email-address"
                type="email"
                placeholder="Enter Email Address"
                value={email}
              />
            </div>
            <div>
              <label
                className="block text-[14px] w-full text-[#5E6366] mb-1"
                for="date"
              >
                Date of Onboarding
              </label>
              <input
                className="outline-none border-[1px] w-full border-[#D9D9D9] rounded-md py-2 px-4"
                id="date"
                type="text"
                placeholder="Enter Date"
                value={createdAt?.split('T')[0]}
              />
            </div>
            <div>
              <label
                className="block text-[14px] w-full text-[#5E6366] mb-1"
                for="business-name"
              >
                Business Name
              </label>
              <input
                className="outline-none border-[1px] w-full border-[#D9D9D9] rounded-md py-2 px-4"
                id="business-name"
                type="text"
                placeholder="Enter Business Name"
                value={agentId}
              />
            </div>
            <div>
              <label
                className="block text-[14px] w-full text-[#5E6366] mb-1"
                for="geo-location"
              >
                Geo Location
              </label>
              <input
                className="outline-none border-[1px] w-full border-[#D9D9D9] rounded-md py-2 px-4"
                id="geo-location"
                type="text"
                placeholder="Enter Geo Location"
                value={location?.city}
              />
            </div>
          </div>
        </form>
      </div>
      <BankInformation />

      {/* UPI Form Start  */}

      {/* <div>
      <div className="flex justify-between items-center">
        <h1 className="text-[1.25rem] font-medium">UPI Details</h1>
        <button className="bg-[#25BF17] text-white font-medium px-6 py-3 flex items-center gap-3 rounded-md">
          <Icon className="text-[1.2rem]" icon="tabler:plus" />
          <span>Add UPI Details</span>
        </button>
      </div>

      <form className="md:mx-[3rem] mb-[10rem] border-[1px] border-gray-200 rounded-md shadow-2xl p-5 mt-5">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-x-16 gap-y-8">
          <div>
            <label
              className="block text-[14px] w-full text-[#5E6366] mb-1"
              for="account-number"
            >
              UPI App
            </label>
            <select className="outline-none bg-[#EFF1F999] border-[1px] w-full border-[#D9D9D9] rounded-md py-2 px-4">
              <option value="Paytim">Paytim</option>
              <option value="googlepay">Googlepay</option>
              <option value="phonepe">Phonepe</option>
              <option value="amazonpay">Amazonpay</option>
            </select>
          </div>

          <div>
            <label
              className="block text-[14px] w-full text-[#5E6366] mb-1"
              for="upi-id"
            >
              Enter UPI ID
            </label>
            <input
              className="outline-none bg-[#EFF1F999] border-[1px] w-full border-[#D9D9D9] rounded-md py-2 px-4"
              id="upi-id"
              type="text"
              placeholder="Enter UPI ID"
            />
            <div className="flex items-center gap-3 mt-1">
              <span>Manikanta Putta</span>
              <Icon
                className="text-[#24A305] text-[1.5rem]"
                icon="ri:checkbox-circle-fill"
              />
            </div>
          </div>
        </div>
      </form>
     </div> */}

      {/* UPI Form End  */}
      <UPIForm />
    </div>
  );
}
