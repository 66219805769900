import React, { useEffect, useMemo, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { Icon } from "@iconify/react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import {
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Option,
  Select,
} from "@material-tailwind/react";
import CustomCheckbox from "../../../helpers/CustomCheckbox";
import { Link, useOutletContext } from "react-router-dom";
import RejectModal from "./RejectModal";
import { APIurls } from "../../../api/apiConstant";
import { useQuery } from "react-query";
import { useAuth } from "../../../store/AuthContext";
import moment from "moment";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import ProccessRequestModal from "./ProccessRequestModal";
import RejectRequestModal from "./RejectRequestModal";


export default function FundRequestList() {
  const [fundRequestsData, isLoading, refetch] = useOutletContext();
  const [searchKey, setSearchKey] = useState("");
  const [loadingStates, setLoadingStates] = useState({});
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showProcessModal, setShowProcessModal] = useState(false);
  const [isRefetch, setIsRefetch] = useState(false);
  const [id, setId] = useState("");
  const { getAccessToken } = useAuth();
  const { fetchAgentDetails } = useAuth();
  const [amountRequested, setAmountRequested] = useState("");
  const [ticketId, setTicketId] = useState("");

  const [filteredItems, setFilteredItems] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const flatpickrRef = useRef();
  const [status, setStatus] = useState("");
  

  let completed = useMemo(() => (
    fundRequestsData?.filter((data) => data?.status === "PROCESSED")
  ), [fundRequestsData])
  let pending = useMemo(() => (
    fundRequestsData?.filter((data) => data?.status === "SUBMITTED")
  ), [fundRequestsData])


  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        padding: "10px 10px",
        color: "#212529",
      },
    },
    head: {
      style: {
        borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        fontSize: "15px",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#565656",
        padding: "10px 10px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    pagination: {
      style: {
        boxShadow: "10px 5px 5px #ddd",
        marginBottom: "5px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1600px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };
  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      minWidth: "60px",
      maxWidth: "60px",
    },
    {
      name: "User Name",
      id: "title",
      selector: (row) => row.UserID?.FirstName + " " + row.UserID?.LastName,
      // sortable: true,
      center: true,
      grow: 2.4,
      icon: "solar:sort-outline",
    },
    {
      name: "Ticket ID",
      id: "ticketId",
      selector: (row) => row.ticket_id,
      // sortable: true,
      // center: true,
      grow: 1.5,
      icon: "solar:sort-outline",
    },
    {
      name: "Country & Currency",
      id: "countryCurrency",
      // sortable: true,
      cell: (row) => (
        <div className="flex items-center space-x-2">
          {/* <Icon
            icon="emojione-v1:flag-for-india"
            className="text-[#272727] max-sm:text-[0.9rem] w-6 h-6"
          /> */}
          <span>{row.UserID?.location?.country} | {row.UserID?.Currency}</span>
        </div>
      ),
      center: true,
      grow: 2.5,
      icon: "solar:sort-outline",
    },
    {
      name: "Coins Requested",
      id: "coinsRequested",
      selector: (row) => row.amountRequested,
      // sortable: true,
      center: true,
      grow: 1.9,
      icon: "solar:sort-outline",
    },
    {
      name: "Date of Requesting",
      id: "dateOfRequesting",
      selector: (row) => moment(row.createdAt).format("DD MMMM YYYY"),
      // sortable: true,
      center: true,
      grow: 2.4,
      icon: "solar:sort-outline",
    },
    {
      name: "Payment Proof",
      id: "paymentProof",
      selector: (row) => (
        <div>
          <a href={row.Payment_Image} target="_blank" rel="noreferrer"
            className="underline text-blue-500 font-medium">
            View Proof
          </a>
        </div>
      ),
      // sortable: true,
      center: true,
      grow: 1.7,
      icon: "solar:sort-outline",
    },
    {
      name: "Status",
      id: "status",
      sortable: true,
      selector: (row) => (
        <div className={`flex justify-center items-center gap-2 w-32 py-2 font-semibold rounded-md
          ${row.status?.toLowerCase() === "processed" ? "text-[#25BF17] bg-[#D1F0CF]" : row.status?.toLowerCase() === "rejected" ? "text-[#FF0023] bg-[#FDCAD1]" : row.status?.toLowerCase() === "hold" ? "text-[#1A202C] bg-[#CFD0D3]" : "text-[#FFCE06] bg-[#f1ece3]"}`}>
          <span className={`${row.status?.toLowerCase() === "processed" ? "bg-[#25BF17]" : row.status?.toLowerCase() === "rejected" ? "bg-[#FF0023]" : row.status?.toLowerCase() === "hold" ? "bg-[#1A202C]" : "bg-[#FFCE06]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
          <h2>{row.status}</h2>
        </div>
      ),
      center: true,
      grow: 1.7,
      icon: "solar:sort-outline",
    },
    {
      name: "Action",
      id: "action",
      cell: (row) => (
        <div className="flex gap-3">
          {/* {
            loadingStates[row._id] && loadingStates.status === "PROCESSED" ? (
              <ThreeDots
                height="50"
                width="50"
                radius="9"
                color="#25BF17"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <IconButton onClick={() => updateFundRequestStatusHandler(row._id, "PROCESSED")}
                className="shadow-none hover:shadow-none focus:shadow-none text-[#25BF17] bg-[#E8F7E6] rounded-md border border-[#25BF17]"
              >
                <Icon icon="mdi:tick" className="text-[1.5rem]" />
              </IconButton>
            )
          } */}
          <IconButton onClick={() => showProcessModalHandler(row._id, row.amountRequested, row.ticket_id)}
            className="shadow-none hover:shadow-none focus:shadow-none text-[#25BF17] bg-[#E8F7E6] rounded-md border border-[#25BF17]"
          >
            <Icon icon="mdi:tick" className="text-[1.5rem]" />
          </IconButton>
          {
            loadingStates[row._id] && loadingStates.status === "HOLD" ? (
              <ThreeDots
                height="50"
                width="50"
                radius="9"
                color="#25BF17"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <IconButton onClick={() => updateFundRequestStatusHandler(row._id, "HOLD")}
                className="shadow-none hover:shadow-none focus:shadow-none text-[#1A202C] bg-[#CFD0D3] rounded-md border border-[#1A202C]"
                disabled={row.status === "HOLD"}
              >
                <Icon
                  icon="mdi:user-block"
                  className="text-[1.5rem]"
                />
              </IconButton>
            )
          }
          {/* {
            loadingStates[row._id] && loadingStates.status === "REJECTED" ? (
              <ThreeDots
                height="50"
                width="50"
                radius="9"
                color="#25BF17"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <IconButton onClick={() => updateFundRequestStatusHandler(row._id, "REJECTED")}
                className="shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] rounded-md border border-[#FF0023]"
              >
                <Icon
                  icon="material-symbols:close"
                  className="text-[1.5rem]"
                />
              </IconButton>
            )
          } */}
          <IconButton onClick={() => showRejectModalHandler(row._id)}
            className="shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] rounded-md border border-[#FF0023]"
          >
            <Icon
              icon="material-symbols:close"
              className="text-[1.5rem]"
            />
          </IconButton>
        </div>
      ),
      center: true,
      grow: 1.8,
    },
  ];

  const dateOptions = {
    mode: "range",
    dateFormat: "d-m-Y",
  };

  // const fetchFundRequestData = async () => {
  //   const token = await getAccessToken()

  //   const response = await fetch(APIurls.fetchFundRequests, {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     }
  //   });
  //   if (!response.ok) {
  //     throw new Error("Failed to Fetch Fund Requests");
  //   }
  //   const result = await response.json();

  //   console.log("Fund Requests...", result?.response?.fundRequest);
  //   return result?.response?.fundRequest?.reverse();
  // };
  // const { data: fundRequest, isLoading, refetch } = useQuery('fundRequestData', fetchFundRequestData);

  const updateFundRequestStatusHandler = async (mongoId, statusType) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [mongoId]: true,
      status: statusType,
    }))
    const token = await getAccessToken()
    try {
      const response = await fetch(`${APIurls.updateFundRequestStatus}/${mongoId}?status=${statusType}`, {
        method: 'PUT',
        // body: JSON.stringify({
        //   statusType: statusType
        // }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      })
      if (!response.ok) {
        throw new Error("Failed to Update Fund Request status");
      }
      const result = await response.json();
      console.log(result);
      toast.success("Fund Request status Updated Successfully");
      refetch();
      fetchAgentDetails(token);
    } catch (error) {
      console.warn("Failed to Update Fund Request Status");
      toast.error("Failed to Update Fund Request Status");
    }
    setLoadingStates((prevState) => ({
      ...prevState,
      [mongoId]: false,
      status: "",
    }))
  }

  const handleDateChange = (selectedDates) => {
    console.log(selectedDates.length);

    if (selectedDates.length === 1) {
      setStartDate(selectedDates[0]);
    }
    if (selectedDates.length === 2) {
      setEndDate(selectedDates[1]);
    }
  };

  const clearDatesHandler = () => {
    setStartDate(null);
    setEndDate(null);
    flatpickrRef.current.flatpickr.clear();  // only clear's the date from input field
  }

  const showRejectModalHandler = (id) => {
    setId(id);
    setShowRejectModal(true);
  }
  const showProcessModalHandler = (id, amount, ticket_id) => {
    setId(id);
    setShowProcessModal(true);
    setAmountRequested(amount);
    setTicketId(ticket_id);
  }
  if (isRefetch) {
    refetch();
    setIsRefetch(false);
  }



  useEffect(() => {
    let mapData = fundRequestsData?.map((item, index) => {
      return { ...item };
    });
    if (searchKey !== "") {
      mapData = mapData.filter((item) => {
        return item?.Name?.toLowerCase().includes(searchKey.toLowerCase());
      });
    }
    if (startDate && endDate) {
      console.log("DATE...........DATE...........DATE");
      const dStart = new Date(startDate);
      dStart.setUTCHours(0, 0, 0, 0);
      const dEnd = new Date(endDate);
      dEnd.setUTCHours(23, 59, 59, 999);
      mapData = mapData.filter((item) => {
        const itemDate = new Date(item.createdAt);
        return (
          itemDate.getTime() >= dStart.getTime() && itemDate.getTime() <= dEnd.getTime()
        );
      });
    }
    if (status !== "") {
      if (status === "ALL") {
        setFilteredItems(mapData);
      } else {
        mapData = mapData.filter((item) => item.status === status);
      }
    }
    setFilteredItems(mapData);
  }, [searchKey, startDate, endDate, fundRequestsData, status]);

  return (
    <div className="py-[1rem] w-auto mb-10">
      {/* visible till Medium screen */}
      <div
        className="md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[full] my-[1rem]"
        style={{
          border: "0.6px solid #DFDFDF",
          borderRadius: "6px",
        }}
      >
        <div className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md ">
          <Icon
            icon="iconamoon:search-bold"
            className="text-[#272727] max-sm:text-[0.9rem]"
          />
        </div>
      </div>

      <div className="flex flex-col max-xs:items-start xs:flex-row gap-[0.5rem] sm:gap-[1rem] justify-between items-center pb-[1rem]">
        {/* Visible from Medium Screen */}
        <div className="flex gap-[1rem] items-center">
          <div
            className="max-md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[300px] lg:w-[350px]"
            style={{
              border: "0.6px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <input
              type="text"
              className="flex-1 bg-transparent  placeholder-[#848484] outline-none "
              placeholder="Search by User Name"
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value);
              }}
            />
            {
              !searchKey ? (
                <button type="button"
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:search" className="text-white" />
                </button>
              ) : (
                <button onClick={() => setSearchKey("")}
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:cross" className="text-white" />
                </button>
              )
            }
          </div>
          <div className="max-md:w-full flex items-center gap-[0.5rem] py-[0.5rem] px-[1rem]"
            style={{
              border: "0.6px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <Flatpickr
              ref={flatpickrRef}
              className="text-[0.8rem] sm:text-[1rem] bg-transparent placeholder-[#848484] outline-none"
              placeholder="Select Date"
              options={dateOptions}
              onChange={(selectedDate) => handleDateChange(selectedDate)}
            />
            {
              !startDate || !endDate ? (
                <button type="button"
                  className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
                  <Icon icon="solar:calendar-outline" className="text-white" />
                </button>
              ) : (
                <button onClick={clearDatesHandler}
                  className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:cross" className="text-white" />
                </button>
              )
            }
          </div>
          <div className="">
            <Select label="Select Status"
              className="text-[0.8rem] sm:text-[1rem] bg-transparent"
              value={status}
              onChange={(value) => setStatus(value)}
            >
              <Option value="SUBMITTED">SUBMITTED</Option>
              <Option value="HOLD">HOLD</Option>
              <Option value="ALL">ALL</Option>
            </Select>
            {/* <RxCross2 /> */}
          </div>
          <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center py-[0.2rem]">
            <div className="">
              <Menu>
                <MenuHandler>
                  <Button className="text-[0.8rem] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-white bg-primary px-[0.4rem] xs:px-[1rem] py-[0.5rem] rounded-md items-center gap-[0.5rem] sm:gap-[1rem] hover:shadow-white shadow-white outline-none">
                    <Icon
                      icon="material-symbols:download"
                      className="text-[1rem] w-[1.2rem] sm:w-[1.5rem] h-auto"
                    />
                    <span className="font-[500]">Export</span>
                  </Button>
                </MenuHandler>
                <MenuList>
                    <MenuItem
                      className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                    >
                      {" "}
                      <Icon
                        icon="fa-solid:file-pdf"
                        className="text-[#BE0A23] text-[1.3rem]"
                      />
                      Download as PDF
                    </MenuItem>
                  <hr className="my-[0.1rem]" />
                  <MenuItem
                    //  onClick={downloadCSV}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                  >
                    <Icon
                      icon="fa-solid:file-csv"
                      className="text-[#29672E] text-[1.3rem]"
                    />
                    Download as CSV
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <DataTable
          columns={columns.map((column) => ({
            ...column,
            name: (
              <div className="flex items-center justify-between">
                <span>{column.name}</span>
                {column.icon && (
                  <Icon icon={column.icon} className="text-gray-600 ml-1" />
                )}
              </div>
            ),
          }))}
          data={filteredItems}
          customStyles={customStyles}
          pagination
          selectableRows
          progressPending={isLoading}
          selectableRowsComponent={CustomCheckbox}
        />
      </div>
      {
        showProcessModal && <ProccessRequestModal id={id} setShowProcessModal={setShowProcessModal} setIsRefetch={setIsRefetch} amountRequested={amountRequested} ticketId={ticketId} />
      }
      {
        showRejectModal && <RejectRequestModal id={id} setShowRejectModal={setShowRejectModal} setIsRefetch={setIsRefetch} />
      }
    </div>
  );
}
