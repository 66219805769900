import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../api/apiConstant";
// Define a service using a base URL and expected endpoints
export const agentApiSlice = createApi({
  reducerPath: "profileApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}`,
    prepareHeaders: (headers, { getState }) => {
      // Assuming your token is stored in Redux state
      const token = getState()?.auth?.token || 'Token Not Found';
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    //Password Rseset APIs
    sendOTPForCP: builder.mutation({
      query: (emailObj) => {
        return {
          url: `/wallet-agent/send-agent-forget-otp`,
          method: "POST",
          body: emailObj,
        };
      },
    }),

    verifyOTPForCP: builder.mutation({
      query: (verifyOtpInfo) => {
        return {
          url: `/wallet-agent/verify-agent-forget-otp`,
          method: "POST",
          body: verifyOtpInfo,
        };
      },
    }),

    updatePwdForCP: builder.mutation({
      query: (passwords) => {
        return {
          url: `/wallet-agent/change-agent-pass`,
          method: "POST",
          body: passwords,
        };
      },
    }),
  }),
});

export const {
  useSendOTPForCPMutation,
  useVerifyOTPForCPMutation,
  useUpdatePwdForCPMutation
} = agentApiSlice;
