import { Link, useNavigate } from "react-router-dom";
import authBgImage from '../assets/auth-bg-img.svg'
import strikeLogo from "../assets/strike-logo.svg"
import { Icon } from "@iconify/react";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import OtpInput from "react-otp-input";
import "./OTPStyles.css";
import { useSendOTPForCPMutation, useVerifyOTPForCPMutation } from "../store/agentApiSlice";
import LoadingButtonState from "../components/ui/LoadingButtonState";
import { Input } from "rsuite";


export default function VerifyOtpForm() {
  const navigate = useNavigate();
  const {email, reference} = useSelector((state) => state.OTP) || {};

  const [otp, setOtp] = useState("");
  const [showError, setShowError] = useState(false);
  const [verifyOTPApi, {isLoading, isError, isSuccess, error, data}] = useVerifyOTPForCPMutation();
  const [sendOTPAgainApi, { isLoading:isLoadingSendOTP, isError:isErrorSendOTP, isSuccess:isSuccessSendOTP, error:sendOTPError, data:SendOTPData }] =
  useSendOTPForCPMutation();

  const handleVerifyOTP = async(e) => {
    e.preventDefault();
    if (!email) {
      return setShowError(true);
    }

    const result = await verifyOTPApi({email, otp_number:Number(otp), otp_reference:reference})
    if (!result.error && result.data) {
      navigate("/reset-password")
    }
  };

  const handleSendOTPAgain = (e)=>{
    e.preventDefault()
    sendOTPAgainApi({EmailID:email})
  };


  //Show toast message for OTP verification
  useEffect(() => {
    if (!isLoading && isError) {
      toast.error(error?.data?.message, { autoClose: 2000 });
    }

    if (!isLoading && !isError && isSuccess) {
      toast.success(data?.message, { autoClose: 2000 });
    }
  }, [isError, isSuccess, isLoading, data, error]);


   //Show toast message for Sending OTP Again
   useEffect(() => {
    if (!isLoadingSendOTP && isErrorSendOTP) {
      toast.error(sendOTPError?.data?.message, { autoClose: 2000 });
    }

    if (!isLoadingSendOTP && !isErrorSendOTP && isSuccessSendOTP) {
      toast.success(SendOTPData?.message, { autoClose: 2000 });
    }
  }, [isErrorSendOTP, isSuccessSendOTP, isLoadingSendOTP, SendOTPData, sendOTPError]);

  return (
    <div
    className="w-full min-h-screen max-xs:px-[0.5rem] bg-cover bg-[#F1F6FA] bg-center bg-repeat flex justify-center items-center"
    style={{ backgroundImage: `url(${authBgImage})` }}
  >
    <div className="bg-white flex flex-col min-w-[20rem] xs:min-w-[28.75rem] justify-center  px-[1rem]  xs:px-[4rem] py-[1rem] xs:py-[3rem] gap-[1rem] xs:gap-[1.8rem] rounded-[10px]  ">
        <div>
          <img
            src={strikeLogo}
            alt=""
            className=""
          />
        </div>

      <form
      onSubmit={handleVerifyOTP}
      >
      <div className="">
                <Input
                  className="outline-none !border-b-[1px] !bg-[#F7F4F2] !border-[#000] py-[0.3rem] px-[1rem] w-full"
                  type="email"
                  label="Email*"
                  name="email"
                  value={email}
                  readOnly
                />
                {!email && showError && (
                  <p className="text-[14px] text-red-500">Email is required*</p>
                )}
              </div>

              <div className="my-[2.5rem]">
                <div className="">
                  {/* OTP Here  */}
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    shouldAutoFocus={true}
                    containerStyle="otp-container"
                    renderInput={(props, index) => (
                      <input
                        {...props}
                        className="otp-input"
                      />
                    )}
                  />
                </div>
                <p className="text-right mt-[1.5rem] mr-[0.6rem]">
                  <span>Didn’t receive OTP?</span>{" "}
                  <button onClick={handleSendOTPAgain} className="text-[#24B24B] font-semibold hover:underline">
                    Send Again
                  </button>
                </p>
              </div>
              <div className="text-center mt-[1.5rem]">
                <button className="bg-primary text-white duration-300 w-full font-semibold py-[1rem]">
                  Verify OTP
                </button>
              </div>
      </form>
      <ToastContainer />
    </div>
  </div>
  );
}
