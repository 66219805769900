import { Icon } from '@iconify/react';
import { useAuth } from '../../../store/AuthContext';
import { APIurls } from '../../../api/apiConstant';
import { useQuery } from 'react-query';
import { useMemo } from 'react';
export default function Lotteries() {
  const { agentDetails, getAccessToken } = useAuth();
  
  const fetchFundRequestData = async () => {
    const token = await getAccessToken()

    const response = await fetch(APIurls.fetchFundRequests, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error("Failed to Fetch Fund Requests");
    }
    const result = await response.json();

    console.log("Fund Requests...", result?.response?.fundRequest);
    return result?.response?.fundRequest?.reverse();
  };
  const { data: fundRequest, isLoading, refetch } = useQuery('fundRequestData', fetchFundRequestData);

  let completed = useMemo(() => (
    fundRequest?.filter((data) => data?.status === "PROCESSED")
  ), [fundRequest])
  let pending = useMemo(() => (
    fundRequest?.filter((data) => data?.status === "SUBMITTED")
  ), [fundRequest])


  return (
    <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6 mb-[40px]">
      {/* badge -1: Total Earned  */}
      <div className='py-3 px-4 rounded-lg shadow-md border-[1px] border-gray-300 flex flex-col'>
        <header className='flex justify-between items-center'>
            <h3 className='text-[14px] text-[#858585]'>Wallet Balance</h3>
        </header>

        <div>
        <h3 className='text-[#25BF17] text-[20px] font-bold'>₹{agentDetails?.wallet}</h3>
        <div className="flex items-center gap-2">
        </div>
        </div>
      </div>

      {/* badge -2: Total Lotteries  */}
      <div className='py-3 px-4 rounded-lg shadow-md border-[1px] border-gray-300'>
        <header className='flex justify-between items-center'>
            <h3 className='text-[14px] text-[#858585]'>Total Requests</h3>
            <Icon className='text-[#25BF17] w-8 h-8' icon="game-icons:ticket" />
        </header>
        <h3 className='text-[#25BF17] text-[20px] font-bold'>{fundRequest?.length}</h3>
    </div>

    {/* badge -3: Completed Lotteries  */}
    <div className='py-3 px-4 rounded-lg shadow-md border-[1px] border-gray-300'>
        <header className='flex justify-between items-center'>
            <h3 className='text-[14px] text-[#858585]'>Approved Requests</h3>
            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 32 32" fill="none">
         <path d="M16.25 0C17.7188 0 19.1354 0.1875 20.5 0.5625C21.8646 0.9375 23.1354 1.47917 24.3125 2.1875C25.4896 2.89583 26.5677 3.72917 27.5469 4.6875C28.526 5.64583 29.3646 6.72396 30.0625 7.92188C30.7604 9.11979 31.2969 10.3958 31.6719 11.75C32.0469 13.1042 32.2396 14.5208 32.25 16C32.25 17.4688 32.0625 18.8854 31.6875 20.25C31.3125 21.6146 30.7708 22.8854 30.0625 24.0625C29.3542 25.2396 28.5208 26.3177 27.5625 27.2969C26.6042 28.276 25.526 29.1146 24.3281 29.8125C23.1302 30.5104 21.8542 31.0469 20.5 31.4219C19.1458 31.7969 17.7292 31.9896 16.25 32C14.7812 32 13.3646 31.8125 12 31.4375C10.6354 31.0625 9.36458 30.5208 8.1875 29.8125C7.01042 29.1042 5.93229 28.2708 4.95312 27.3125C3.97396 26.3542 3.13542 25.276 2.4375 24.0781C1.73958 22.8802 1.20312 21.6042 0.828125 20.25C0.453125 18.8958 0.260417 17.4792 0.25 16C0.25 14.5312 0.4375 13.1146 0.8125 11.75C1.1875 10.3854 1.72917 9.11458 2.4375 7.9375C3.14583 6.76042 3.97917 5.68229 4.9375 4.70312C5.89583 3.72396 6.97396 2.88542 8.17188 2.1875C9.36979 1.48958 10.6458 0.953125 12 0.578125C13.3542 0.203125 14.7708 0.0104167 16.25 0ZM25.6719 10.7031L23.5469 8.57812L13.25 18.875L8.95312 14.5781L6.82812 16.7031L13.25 23.125L25.6719 10.7031Z" fill="#25BF17"/>
            </svg>
        </header>
        <h3 className='text-[#25BF17] text-[20px] font-bold'>{completed?.length}</h3>
    </div>

    {/* badge -4: Pending Lotteries  */}
    <div className='py-3 px-4 rounded-lg shadow-md border-[1px] border-gray-300'>
        <header className='flex justify-between items-center'>
            <h3 className='text-[14px] text-[#858585]'>Pending Requests</h3>
            <Icon className='text-[#25BF17] w-6 h-6' icon="grommet-icons:in-progress" />
        </header>
        <h3 className='text-[#25BF17] text-[20px] font-bold'>{pending?.length}</h3>
    </div>
    </div>
  )
}
