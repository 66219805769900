import React from "react";

export default function CarouselShimmerLoader() {
  return (
    <section>
      <div className="lg:w-[90%] w-[100%] mx-auto">
        <div className="md:h-[450px] h-[200px]">
          <div className="animate-pulse bg-gray-400 rounded-md h-full w-full"></div>
        </div>
        <div className="flex justify-center gap-[1rem] mt-[1.5rem]">
        <div className="animate-pulse bg-gray-400 rounded-md h-[4rem] w-[6rem]"></div>
        <div className="animate-pulse bg-gray-400 rounded-md h-[4rem] w-[6rem]"></div>
        <div className="animate-pulse bg-gray-400 rounded-md h-[4rem] w-[6rem]"></div>
        </div>
      </div>
    </section>
  );
}
