import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";

export default function AddCoinModal({setShowAddCoinModal}) {
  const handleAddCoinByModal = ()=>{
    setShowAddCoinModal(false)
  }
  return (
    <div className="bg-[#0000009c] flex justify-center items-center absolute w-full h-full z-50 top-0 left-0 right-0 bottom-0">
      <div className="overflow-auto lg:mx-[10rem] relative">
        <Icon
          onClick={()=>setShowAddCoinModal(false)}
          className="absolute right-1 top-1 border-[1px] border-red-300 text-red-800 rounded-md cursor-pointer hover:bg-red-800 hover:text-white p-[2px] bg-red-100"
          icon="bitcoin-icons:cross-filled"
          width="2em"
          height="2em"
        />
        <div className="bg-white p-[1.5rem] min-w-[400px] lg:mx-0 mx-[1rem] rounded-md">
          <h3 className="font-semibold mb-5 pt-[1rem] text-[1.75rem]">
            Request Details
          </h3>
          <div className="grid lg:grid-cols-3 grid-cols-2 gap-[1.5rem]">
            <div>
              <p className="text-gray-600 text-[1.25rem] mb-1">User Name</p>
              <h5 className="font-medium text-[1.5rem]">Billal Hossain</h5>
            </div>
            <div>
              <p className="text-gray-600 text-[1.25rem] mb-1">Ticket ID</p>
              <h5 className="font-medium text-[1.5rem]">536536545463</h5>
            </div>
            <div>
              <p className="text-gray-600 text-[1.25rem] mb-1">Payment Method</p>
              <h5 className="font-medium text-[1.5rem]">UPI</h5>
            </div>
            <div>
              <p className="text-gray-600 text-[1.25rem] mb-1">UTR Number</p>
              <h5 className="font-medium text-[1.5rem]">ABC4325425</h5>
            </div>
            <div>
              <p className="text-gray-600 text-[1.25rem] mb-1">Coins Requested</p>
              <h5 className="font-medium text-[1.5rem]">1900</h5>
            </div>
            <div>
              <p className="text-gray-600 text-[1.25rem] mb-1">Payment Proof</p>
              <h5 className="font-medium text-[1.5rem]">Billal.png</h5>
            </div>
            <div>
              <p className="text-gray-600 text-[1.25rem] mb-1">
                Date Of Requesting
              </p>
              <h5 className="font-medium text-[1.5rem]">25/05/2024</h5>
            </div>
            <div className="md:col-span-3">
              <p className="text-gray-600 text-[1.25rem] mb-1">Remarks</p>
              <h5 className="font-medium text-[1.5rem]">
                Note: Pay to the provided bank details, submit proof to the
                agent, and receive coins in your wallet upon verification.
              </h5>
            </div>
            <div className="md:col-span-3 flex justify-between items-center">
              <h3 className="text-[1.875rem] font-bold">30,000 Coins</h3>
              <button onClick={handleAddCoinByModal} className="bg-primary font-medium text-white h-[3rem] px-[1rem] border-[1px] border-gray-300 py-1">
                Add Coins
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
