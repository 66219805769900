import { useEffect, useState } from "react";
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Carousel.css";
import CarouselShimmerLoader from "../../ui/CarouselShimmerLoader";
export default function ImgGalleries() {
  const [banners, setBanners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessToken } = useAuth();
  console.log("Banners Data==> ", banners);

  async function fetchBannersInfo() {
    setIsLoading(true);
    const token = await getAccessToken();
    try {
      const response = await fetch(APIurls.fetchBannerDetails, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch agent details");
      }
      const result = await response.json();
      setBanners(result?.response?.banners);
      setIsLoading(false);
    } catch (error) {
      console.warn("Failed to fetch banner details");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchBannersInfo();
  }, []);

  const handleNavigate = (link) => {
    window.open(link, "_blank");
  };
  return (
    <section className="mb-[3rem]">
      {isLoading ? (
        // <p className="text-3xl font-bold flex justify-center items-center min-h-[200px]">Loading...</p>
        <CarouselShimmerLoader/>
      ) : (
        <Carousel
          infiniteLoop
          axis="horizontal"
          autoPlay={true}
          className="lg:w-[90%] w-[100%] mx-auto"
        >
          {banners?.map((banner) => (
            <div
              onClick={() => handleNavigate(banner?.ExternalLink)}
              className="lg:h-[450px] md:h-[300px] cursor-pointer"
            >
              <img src={banner?.BannerImg} />
            </div>
          ))}
        </Carousel>
      )}
    </section>
  );
}
