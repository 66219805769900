import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    email:'',
    reference:''
};

const OTPSlice = createSlice({
    name: 'OTP',
    initialState: initialState,
    reducers: {
        addOTPData(state, action){
           return state = action.payload
        }
    }
})

export const { addOTPData } = OTPSlice.actions;

export default OTPSlice.reducer;