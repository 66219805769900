import React, { useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";

const UpiFormComponent = () => {
  const [upiForms, setUpiForms] = useState([]);

  // Function to handle adding a new UPI form
  const addUpiForm = () => {
    const newForm = {
      id: new Date().getTime(), // Unique identifier for each form
      upiApp: "Paytim", // Default value, can be adjusted as needed
      upiId: "",
      checked: false, // Default value for checkbox
    };
    setUpiForms([...upiForms, newForm]);
  };

  // Function to handle deleting a specific UPI form
  const deleteUpiForm = (formId) => {
    const updatedForms = upiForms.filter((form) => form.id !== formId);
    setUpiForms(updatedForms);
  };

  // Function to handle input change in UPI form fields
  const handleInputChange = (formId, key, value) => {
    const updatedForms = upiForms.map((form) => {
      if (form.id === formId) {
        return { ...form, [key]: value };
      }
      return form;
    });
    setUpiForms(updatedForms);
  };

  // Function to handle form submission
  const handleFormSubmit = () => {
    // Implement form submission logic here, e.g., validation and API calls
    console.log(upiForms);
    // Reset or navigate after submission
  };

  // Function to validate form fields
  const validateForm = (form) => {
    return form.upiId.trim() !== "";
    // Add more validation rules as needed
  };

  return (
    <div className="pb-[5rem]">
      <div className="flex md:flex-row flex-col md:justify-between md:items-center">
        <h1 className="text-[1.25rem] font-medium">UPI Details</h1>
        <button
          className="bg-[#25BF17] text-white font-medium md:px-6 px-2 py-3 flex items-center md:gap-3 gap-1 rounded-md"
          onClick={addUpiForm}
        >
          <Icon className="text-[1.2rem]" icon="tabler:plus" />
          <span>Add UPI Details</span>
        </button>
      </div>

      {upiForms.map((form, index) => (
        <form
          key={form.id}
          className="md:mx-[3rem] border-[1px] border-gray-200 rounded-md shadow-2xl p-5 mt-5"
        >
          <div className="flex justify-between items-center">
          <h2 className="text-[1.25rem] font-medium">
            UPI Details-{index + 1}
          </h2>
          <button
            type="button"
            className="border-[1px] border-red-500 text-red-500  hover:bg-red-700 hover:text-white font-bold p-2 rounded"
            onClick={() => deleteUpiForm(form.id)}
          >
            <Icon className="text-2xl" icon="material-symbols:delete" />
          </button>
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-x-16 gap-y-8">
            <div>
              <label
                className="block text-[14px] w-full text-[#5E6366] mb-1"
                htmlFor={`upi-app-${form.id}`}
              >
                UPI App
              </label>
              <select
                id={`upi-app-${form.id}`}
                className="outline-none border-[1px] w-full border-[#D9D9D9] rounded-md py-2 px-4"
                value={form.upiApp}
                onChange={(e) =>
                  handleInputChange(form.id, "upiApp", e.target.value)
                }
              >
                <option value="Paytim">Paytim</option>
                <option value="Googlepay">Googlepay</option>
                <option value="Phonepe">Phonepe</option>
                <option value="Amazonpay">Amazonpay</option>
              </select>
            </div>

            <div>
              <label
                className="block text-[14px] w-full text-[#5E6366] mb-1"
                htmlFor={`upi-id-${form.id}`}
              >
                Enter UPI ID
              </label>
              <input
                id={`upi-id-${form.id}`}
                className="outline-none border-[1px] w-full border-[#D9D9D9] rounded-md py-2 px-4"
                type="text"
                value={form.upiId}
                onChange={(e) =>
                  handleInputChange(form.id, "upiId", e.target.value)
                }
                placeholder="Enter UPI ID"
              />
              {/* <div className="flex items-center gap-3 mt-1">
                <span>Manikanta Putta</span>
                <Icon
                  className={`text-[#24A305] text-[1.5rem] ${
                    form.checked ? "text-green-500" : "text-gray-300"
                  }`}
                  icon="ri:checkbox-circle-fill"
                  onClick={() =>
                    handleInputChange(form.id, "checked", !form.checked)
                  }
                />
              </div> */}
            </div>
          </div>
        </form>
      ))}

      {upiForms.length > 0 && (
        <div className="flex justify-end">
            <button
          className="bg-[#25BF17] text-white px-6 py-3 mt-3 rounded-md"
          onClick={handleFormSubmit}
        >
          Update UPI Details
        </button>
        </div>
      )}
    </div>
  );
};

export default UpiFormComponent;
