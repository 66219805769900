import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSendOTPForCPMutation } from "../store/agentApiSlice";
import LoadingButtonState from "../components/ui/LoadingButtonState";
import authBgImage from '../assets/auth-bg-img.svg'
import strikeLogo from "../assets/strike-logo.svg"
import { addOTPData } from "../store/OTPSlice";
// import { useSendOTPMutation } from "../store/features/profile/profileApi";
// import LoadingButtonState from "../components/LoadingButtonState";
export default function SendOtpForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showError, setShowError] = useState(false);
  const [email, setEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [sendOTPApi, { isLoading, isError, isSuccess, error, data }] =
    useSendOTPForCPMutation();

  const handleSendOTP = async (e) => {
    e.preventDefault();

    if (!email) {
      return setShowError(true);
    }
    if (email && !emailRegex.test(email)) {
      return setInvalidEmail(true);
    }
    const result = await sendOTPApi({ EmailID:email });
    if (!result.error) {
      const reference =  result?.data?.response?.refId;
      dispatch(addOTPData({email, reference}));
      navigate("/verify-otp");
    }
    setInvalidEmail(false);
    setShowError(false);
  };

  //Show toast message for Seding OTP
  useEffect(() => {
    if (!isLoading && isError) {
      toast.error(error?.data?.message, { autoClose: 2000 });
    }

    if (!isLoading && !isError && isSuccess) {
      toast.success(data?.message, { autoClose: 2000 });
    }
  }, [isError, isSuccess, isLoading, data, error]);

  return (
    <div
    className="w-full min-h-screen max-xs:px-[0.5rem] bg-cover bg-[#F1F6FA] bg-center bg-repeat flex justify-center items-center"
    style={{ backgroundImage: `url(${authBgImage})` }}
  >
    <div className="bg-white flex flex-col min-w-[20rem] xs:min-w-[28.75rem] justify-center  px-[1rem]  xs:px-[4rem] py-[1rem] xs:py-[3rem] gap-[1rem] xs:gap-[1.8rem] rounded-[10px]  ">
        <div>
          <img
            src={strikeLogo}
            alt=""
            className=""
          />
        </div>
        <p className="max-w-[400px] text-gray-500 text-[14px]">No worries! just enter your e-mail and we'll send you a OTP,
        verify it and its done.</p>
      <form
        onSubmit={handleSendOTP}
        className="w-full flex flex-col gap-[1rem]"
      >
        <div className="group flex flex-col gap-[0.5rem]">
          <label htmlFor="email" className="text-[1rem] font-[600]">
            Email Address
          </label>
          <div>
          <div className={`flex flex-row text-[0.9rem] font-[600] group-focus-within:bg-white  group-focus-within:shadow-md border items-center rounded-md px-[0.5rem] xs:px-[1rem] py-[0.5rem]`}>
            <div className="">
              <Icon icon="ic:round-mail" className="text-[1.2rem]" />
            </div>

            <input
              type="text"
              name="email"
              placeholder="example@gmail.com"
              className="ml-[1rem] outline-none w-1 border-none flex-grow placeholder:text-[#858585]"
              onChange={(e)=>setEmail(e.target.value)}
            />
          </div>
            {showError && !email && (<p className="text-red-500 text-[14px]">Email is required*</p>)}
            {invalidEmail && email && !emailRegex.test(email) && (<p className="text-red-500 text-[14px]">Invalid Email!</p>)}
          </div>


        </div>

        <div className="flex flex-row justify-center">
        <button
              type="submit"
              className="w-full h-[50px] flex justify-center items-center text-white text-[1.1rem] rounded-md px-[1.5rem] py-[0.8rem] bg-primary font-bold"
              disabled={isLoading}
            >
              {isLoading ?  <LoadingButtonState/> : <span>Send OTP</span>}
            </button>
        </div>
        <Link to="/login">
        <div className="flex items-center gap-1 text-gray-500">
        <Icon icon="ion:arrow-undo" />
        <p>Back to Login</p>
        </div>
        </Link>
      </form>
      <ToastContainer />
    </div>
  </div>
  );
}
