import { Icon } from "@iconify/react";
import DatePicker from "react-datepicker";
import walletBg from "../../../assets/wallet_card.svg";
import { useState } from "react";
import { useAuth } from "../../../store/AuthContext";

export default function WalletCard() {
  const [startDate, setStartDate] = useState(null);
  const { agentDetails } = useAuth();

  return (
    <div style={{ backgroundImage: `url(${walletBg})`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat" }} className="md:w-[714px] sm:w-[500px] w-[300px] h-[220px] rounded-[20px] p-5 mx-8 mb-2">
      <div className="flex sm:flex-row flex-col-reverse justify-between">
        <div className="flex flex-col justify-center h-[200px] sm:py-5 py-2">
          <div className="flex items-center gap-16">
            <p className="text-[20px] text-[#FFF]">Balance:</p>
            {/* <Icon className="w-6 h-6 text-[#FFFFFF]" icon="tabler:reload" /> */}
          </div>
          <h1 className="text-[#FFF] text-[50px] font-bold mt-3">
            {agentDetails?.wallet}
          </h1>
        </div>

        {/* <div className='flex items-center border-2 border-gray-200 p-1 rounded-lg bg-[#FFFFFF] h-[40px] w-[130px]'>
         <DatePicker className='outline-none w-[90px] bg-[#FFFFFF]' selected={startDate} placeholderText='Date' onChange={(date) => setStartDate(date)} />
         <Icon icon="healthicons:calendar" className='w-[22px] h-[22px] text-gray-600'/>
         </div> */}
      </div>
    </div>
  )
}
