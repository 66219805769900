import { Icon } from '@iconify/react';

const todayNotifications = [
  {
      title: 'Meeting Reminder',
      body: 'Lorem ipsum dolor sit amet consectetur. Cursus tempor cursus tempus arcu tempor porta blandit rhoncus hendrerit.',
      time: '10:45 AM'
  },
  {
      title: 'Birthday Celebration',
      body: 'Lorem ipsum dolor sit amet consectetur. Cursus tempor cursus tempus arcu tempor porta blandit rhoncus hendrerit.',
      time: '11:15 AM'
  },
  {
      title: 'Task Deadline Approaching',
      body: 'Lorem ipsum dolor sit amet consectetur. Cursus tempor cursus tempus arcu tempor porta blandit rhoncus hendrerit.',
      time: '1:45 PM'
  },
  {
      title: 'Weather Update',
      body: 'Lorem ipsum dolor sit amet consectetur. Cursus tempor cursus tempus arcu tempor porta blandit rhoncus hendrerit.',
      time: '3:00 PM'
  }
];

const yesterdayNotifications = [
  {
    title: 'Coffee Break',
    body: 'Lorem ipsum dolor sit amet consectetur. Cursus tempor cursus tempus arcu tempor porta blandit rhoncus hendrerit.',
    time: '9:30 AM'
},
{
    title: 'Team Lunch Today',
    body: 'Lorem ipsum dolor sit amet consectetur. Cursus tempor cursus tempus arcu tempor porta blandit rhoncus hendrerit.',
    time: '11:45 AM'
},
{
    title: 'Upcoming Seminar',
    body: 'Lorem ipsum dolor sit amet consectetur. Cursus tempor cursus tempus arcu tempor porta blandit rhoncus hendrerit.',
    time: '2:15 PM'
},
{
    title: 'Network Maintenance',
    body: 'Lorem ipsum dolor sit amet consectetur. Cursus tempor cursus tempus arcu tempor porta blandit rhoncus hendrerit.',
    time: '4:30 PM'
},
];

export default function Notifications() {
  return (
    <div>
        {/* Today's Notifications  */}
        <p className='text-[17px] font-semibold my-[30px] text-[#686868]'>Today</p>
        <div>
          {
            todayNotifications.map(item => (
              <div className='flex xs:flex-row flex-col xs:justify-between xs:items-center bg-[#F6FAFF] mb-6 pr-3 md:pl-8 pl-3 py-5 rounded-[6.452px] gap-3 border-[#FFF] border-[0.538px]'>
                <div>
                <h3 className='text-[19.357px] font-semibold text-[#1A3032]'>{item.title}</h3>
                <p className='text-[17.207px] text-[#6B6C6F]'>{item.body}</p>
                </div>
                
                <div className='flex items-center gap-2'>
                <p className='text-[15.056px] font-semibold text-[#1A3032]'>{item.time}</p>
                <Icon className='text-[#B2B2B2]' icon="entypo:dots-three-vertical" />
                </div>
              </div>
            ))
          }
        </div>


        {/* Yesterday's Notifications  */}
        <p className='text-[17px] font-semibold mt-16 mb-[30px] text-[#686868]'>Yesterday</p>
        <div>
          {
            yesterdayNotifications.map(item => (
              <div className='flex xs:flex-row flex-col xs:justify-between xs:items-center bg-[#F6FAFF] mb-6 pr-3 md:pl-8 pl-3 py-5 rounded-[6.452px] gap-3 border-[#FFF] border-[0.538px]'>
                <div>
                <h3 className='text-[19.357px] font-semibold text-[#1A3032]'>{item.title}</h3>
                <p className='text-[17.207px] text-[#6B6C6F]'>{item.body}</p>
                </div>
                
                <div className='flex items-center gap-2'>
                <p className='text-[15.056px] font-semibold text-[#1A3032]'>{item.time}</p>
                <Icon className='text-[#B2B2B2]' icon="entypo:dots-three-vertical" />
                </div>
              </div>
            ))
          }
        </div>
    </div>
  )
}
