import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Icon } from "@iconify/react";
import "flatpickr/dist/themes/material_green.css";
import {
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import moment from "moment";
import CustomCheckbox from "../../../helpers/CustomCheckbox";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";

export default function WalletList() {
  const { getAccessToken } = useAuth();
  const [searchKey, setSearchKey] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        padding: "10px 10px",
        color: "#212529",
      },
    },
    head: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        fontSize: "15px",
      },
    },

    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        color: "#565656",
        padding: "10px 10px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    pagination: {
      style: {
        boxShadow: "10px 5px 5px #ddd",
        marginBottom: "5px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1500px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };
  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
      icon: "solar:sort-outline",
    },
    {
      name: "Reference ID",
      id: "referenceId",
      selector: (row) => row.requestId,
      center: true,
      grow: 1,
      icon: "solar:sort-outline",
    },
    {
      name: "Type",
      id: "type",
      selector: (row) => "Wallet",
      center: true,
      grow: 1,
      icon: "solar:sort-outline",
    },
    {
      name: "Amount",
      id: "amount",
      selector: (row) => row.paidAmount,
      center: true,
      grow: 1,
      icon: "solar:sort-outline",
    },
    {
      name: "Expected Date",
      id: "expectedDate",
      selector: (row) => moment(row.createdAt).format("DD MMMM YYYY"),
      center: true,
      grow: 1,
      icon: "solar:sort-outline",
    },
    {
      name: "status",
      id: "status",
      selector: (row) => (
        <div className={`flex justify-center items-center gap-2 py-2 w-32 font-semibold rounded-md
          ${row.status?.toLowerCase() === "approved" ? "text-[#25BF17] bg-[#D1F0CF]" : row.status?.toLowerCase() === "rejected" ? "text-[#FF0023] bg-[#FDCAD1]" : row.status?.toLowerCase() === "hold" ? "text-[#1A202C] bg-[#CFD0D3]" : "text-[#FFCE06] bg-[#f1ece3]"}`}>
          <span className={`${row.status?.toLowerCase() === "approved" ? "bg-[#25BF17]" : row.status?.toLowerCase() === "rejected" ? "bg-[#FF0023]" : row.status?.toLowerCase() === "hold" ? "bg-[#1A202C]" : "bg-[#FFCE06]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
          <h2>{row.status}</h2>
        </div>
      ),
      center: true,
      grow: 1,
      icon: "solar:sort-outline",
    },
    {
      name: "Action",
      id: "action",
      cell: (row) => (
        <div className="flex space-x-2">
          <Link to={`/wallets/view/${row.id}`}>
            <IconButton
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
              style={{
                borderRadius: "5.55px",
                border: "0.925px solid #D9D9D9",
                background: "#FFF",
              }}
            >
              <Icon icon="mdi:eye" className="text-[1.5rem]" />
            </IconButton>
          </Link>
        </div>
      ),
      center: true,
      grow: 1,
    },
  ];

  const fetchDepositRequestsData = async () => {
    const token = await getAccessToken()

    const response = await fetch(APIurls.fetchDepositRequests, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error("Failed to Fetch Deposit Requests");
    }
    const result = await response.json();

    console.log("Deposit Requests...", result?.response?.depositRequests);
    return result?.response?.depositRequests?.reverse();
  };
  const { data: depositRequests, isLoading, refetch } = useQuery('depositRequestsData', fetchDepositRequestsData);

  return (
    <div>
      <div className="px-[1rem] py-[1rem] w-auto">
        <div className="flex flex-col max-xs:items-start xs:flex-row gap-[0.5rem] sm:gap-[1rem] justify-center sm:justify-between items-center px-[1rem] py-[0.5rem] rounded-md mb-[1rem]">
          {/* Visible from Medium Screen */}
          <h1 className="text-[16px] font-medium">List</h1>
          <div className="flex md:flex-row flex-col gap-[2rem] items-center">
            <div
              className="flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] lg:w-[350px] md:w-[300px] w-full"
              style={{
                border: "0.6px solid #DFDFDF",
                borderRadius: "6px",
              }}
            >
              <input
                type="text"
                className="flex-1 bg-transparent  placeholder-[#848484] outline-none "
                placeholder="Search here"
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
              />
              {
                !searchKey ? (
                  <button type="button"
                    className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                    <Icon icon="akar-icons:search" className="text-white" />
                  </button>
                ) : (
                  <button onClick={() => setSearchKey("")}
                    className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                    <Icon icon="akar-icons:cross" className="text-white" />
                  </button>
                )
              }
            </div>

            <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center py-[0.2rem]">
              <div className="">
                <Menu>
                  <MenuHandler>
                    <button className="text-[0.8rem] sm:text-[1rem] flex items-center justify-center max-xs:w-full gap-[0.3rem] xs:gap-[0.5rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md">
                      <Icon
                        icon="material-symbols:download"
                        className="text-[1.2rem] sm:text-[1.5rem]"
                      />
                      <span className="whitespace-nowrap">Export</span>
                    </button>
                  </MenuHandler>
                  <MenuList>
                    <MenuItem
                      //  onClick={downloadPdf}
                      className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                    >
                      {" "}
                      <Icon
                        icon="bxs:file-pdf"
                        className="text-[#BE0A23] w-[1.6rem] h-auto"
                      />
                      Download as PDF
                    </MenuItem>
                    <hr className="my-[0.2rem] sm:my-[0.5rem]" />
                    <MenuItem
                      //  onClick={downloadCSV}
                      className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.9rem]"
                    >
                      <Icon
                        icon="fa-solid:file-csv"
                        className="text-[#29672E] w-[1.1rem] h-auto"
                      />
                      Download as CSV
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </div>
          </div>
        </div>


        <div className="bg-white">
          <DataTable
            columns={columns.map((column) => ({
              ...column,
              name: (
                <div className="flex items-center justify-between">
                  <span>{column.name}</span>
                  {column.icon && (
                    <Icon icon={column.icon} className="text-gray-800 ml-1 w-[16px] h-[16px]" />
                  )}
                </div>
              ),
            }))}
            data={depositRequests}
            customStyles={customStyles}
            pagination
            progressPending={isLoading}
            selectableRows
            selectableRowsComponent={CustomCheckbox}
          />
        </div>
      </div>
    </div>
  )
}
