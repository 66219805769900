import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    personalDetails: {
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
        whatsAppNumber: "",
        telegramNumber: "",
        nationality: "",
        passportNumber: null,
        country: null,
        state: null,
        city: null,
        pincode: null,
    },
    bankDetails: {
        accountNumber: "",
        ifscCode: "",
        accountHolderName: "",
        bankName: "",
        bankCity: "",
        branch: "",
        status: "",
    },
    loaderBtn: false,
}

const createAgentSlice = createSlice({
    name: "createAgent",
    initialState: initialState,
    reducers: {
        updatePersonalDetails(state, action) {
            state.personalDetails = { ...state.personalDetails, ...action.payload }
        },
        updateBankDetails(state, action) {
            state.bankDetails = { ...state.bankDetails, ...action.payload }
        },
        updateLoaderBtn(state, action) {
            state.loaderBtn = action.payload;
        }
    }
})

export const { updatePersonalDetails, updateBankDetails, updateLoaderBtn } = createAgentSlice.actions;

export default createAgentSlice.reducer;
