import ImgGalleries from "../agentModule/Dashboard/ImgGalleries";
import Lotteries from "../agentModule/Dashboard/Lotteries";
import LotterySummary from "../agentModule/Dashboard/LotterySummary/LotterySummary";
import { Icon } from "@iconify/react";

export default function AgentPanelDashboard() {
  return (
    <div className='lg:pt-[2.5rem] pl-[2rem] bg-white'>
      <h1 className="flex items-center gap-[1rem] text-primary text-[32px] sm:text-[1.7rem] font-bold mb-[30px]">
      <Icon icon="ic:round-dashboard" className="text-[2rem]" />
      Dashboard
    </h1>
        <ImgGalleries/>
        <Lotteries></Lotteries>
        <LotterySummary></LotterySummary>
    </div>
  )
}

