import React, { useEffect } from 'react'
import kycPending from '../assets/kyc_pending.svg'
import strikeLogo from '../assets/strike-logo.svg'

export default function KYCPending() {

  useEffect(() => {
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/66a3475ebecc2fed692b56bd/1i3mstiqh';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    // Insert the script into the document head
    const script0 = document.getElementsByTagName('script')[0];
    script0.parentNode.insertBefore(script, script0);

    return () => {
      // Cleanup script if the component is unmounted
      script0.parentNode.removeChild(script);
    };
  }, []);

  const contactSupportHandler = () => {
    if (window && window.Tawk_API) {
      window.Tawk_API.maximize();
    }
  }

  return (
    <div className='flex flex-col gap-10'>
      <header className='h-[4.5rem] flex shadow-md fixed w-full z-50 py-2 px-16 bg-white'>
        <img src={strikeLogo} alt="logo" />
      </header>
      <div className='pt-[4.5rem] flex flex-col justify-center items-center text-center px-4'>
        <div className='flex items-center justify-center'>
          <img src={kycPending} alt="kyc_pending" className='h-[300px] sm:h-[400px]' />
        </div>
        <h1 className='text-[1.8rem] sm:text-[2rem] font-bold'>
          KYC Pending
        </h1>
        <p className='text-[1rem] sm:text-[1.1rem] font-normal mt-2'>
          Please complete your KYC and you can use your agent account. Please contact our support team or write an mail at <strong>support@strike.com</strong>.
        </p>
        <div className='flex flex-col xs:flex-row items-center justify-center gap-8 w-full mt-12 mb-8'>
          <button onClick={contactSupportHandler}
            className='text-[1rem] font-semibold bg-primary text-white py-3 xs:py-4 w-full lg:w-[400px] rounded-lg'>
            Contact Support
          </button>
          {/* <button className='text-[1rem] font-semibold bg-primary text-white py-3 xs:py-4 w-full lg:w-[400px] rounded-lg'>
            Complete KYC
          </button> */}
        </div>
      </div>
    </div>
  )
}
