import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import LoadingButtonState from "../components/ui/LoadingButtonState";
import authBgImage from "../assets/auth-bg-img.svg";
import strikeLogo from "../assets/strike-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { useUpdatePwdForCPMutation } from "../store/agentApiSlice";
export default function ResetPasswordForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { email, reference } = useSelector((state) => state.OTP);
  const [
    changePasswordByEmailApi,
    { isLoading, isError, isSuccess, error, data },
  ] = useUpdatePwdForCPMutation();

  const [visible, setVisible] = useState(false);
  const [isVisibleConfirmPassword, setIsVisibleConfirmPassword] =
    useState(false);
  const [showError, setShowError] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%._-])[A-Za-z\d!@#$%._-]{8,}$/;

  const handleShowHidePwd = () => {
    setVisible(!visible);
  };

  const valid = (id) => {
    const element = document.getElementById(id);
    element.style.color = "#24B24B";
    element.style.borderColor = "#24B24B";
  };

  const inValid = (id) => {
    const element = document.getElementById(id);
    element.style.color = "red";
    element.style.borderColor = "red";
  };

  const handleChange = (e) => {
    //validate password
    const pwd = e.target.value;
    if (pwd.match(/[A-Z]/) !== null) {
      valid("upper");
    } else {
      inValid("upper");
    }

    if (pwd.match(/[a-z]/) !== null) {
      valid("lower");
    } else {
      inValid("lower");
    }

    if (pwd.match(/[0-9]/) !== null) {
      valid("num");
    } else {
      inValid("num");
    }

    if (pwd.match(/[@&#$%]/) !== null) {
      valid("char");
    } else {
      inValid("char");
    }

    if (pwd.length >= 8) {
      valid("more8");
    } else {
      inValid("more8");
    }

    if (passwordRegex.test(pwd)) {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }
    setPassword(pwd);
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (!password || !confirmPassword) {
      return setShowError(true);
    }

    if (password !== confirmPassword) {
      return setShowError(true);
    }

    //Validate password
    if (!passwordRegex.test(password)) {
      setIsPasswordValid(false);
      setShowError(true);
      return;
    }

    const result = await changePasswordByEmailApi({
      newPassword: password,
      otp_reference: reference,
      email: email,
    });
    if (!result?.error) {
      setPassword("");
      setConfirmPassword("");
      setShowError(false);
      navigate("/login");
    }
  };
  // billalHossain76@
  //Handle api calling states(error/success)
  useEffect(() => {
    if (!isError && !isLoading && isSuccess) {
      toast.success("Updated password successfully.");
    }
    if (isError && !isLoading && !isSuccess) {
      toast.error(error?.data?.message);
    }
  }, [isLoading, isError, isSuccess, data, error]);
  return (
    <div
      className="w-full min-h-screen max-xs:px-[0.5rem] bg-cover bg-[#F1F6FA] bg-center bg-repeat flex justify-center items-center"
      style={{ backgroundImage: `url(${authBgImage})` }}
    >
      <div className="bg-white flex flex-col min-w-[20rem] xs:min-w-[28.75rem] justify-center  px-[1rem]  xs:px-[4rem] py-[1rem] xs:py-[3rem] gap-[1rem] xs:gap-[1.8rem] rounded-[10px]  ">
        <div>
          <img src={strikeLogo} alt="" className="" />
        </div>

        {/* Form  */}
        <form
          className="max-w-[400px]"
          autoComplete="off"
          onSubmit={handleChangePassword}
        >
          {/* <h3 className="text-[2rem] text-center mb-[0.5rem] font-semibold">
              Update Password Now
            </h3> */}
          <div className="mb-[1.5rem] flex md:flex-row flex-col gap-[1.5rem]"></div>

          <>
            <div className="relative mt-[1.8rem]">
              <input
                className="outline-none border-b-[1px] bg-[#fff] border-[#24B24B] py-[0.3rem] px-[1rem] w-full"
                type={visible ? "text" : "password"}
                placeholder="Enter New Password*"
                name="password"
                value={password}
                onChange={handleChange}
              />
              {visible ? (
                <Icon
                  onClick={handleShowHidePwd}
                  className="absolute text-2xl top-2 right-2 text-[#B1B1B1] cursor-pointer"
                  icon="tabler:eye-off"
                />
              ) : (
                <Icon
                  onClick={handleShowHidePwd}
                  className="absolute text-2xl top-2 right-2 text-[#B1B1B1] cursor-pointer"
                  icon="mi:eye"
                />
              )}
            </div>
            {!password && showError && (
              <p className="text-red-500 text-[14px]">Password is required*</p>
            )}
          </>

          <div className="flex gap-[1rem] flex-wrap mb-[1.5rem] pt-[0.5rem]">
            <p
              id="more8"
              className="text-[0.75rem] text-[#b6b9b7] border-[1px] border-[#b6b9b7] p-[0.25rem]"
            >
              Min 8 Max 15 Characters
            </p>
            <p
              id="upper"
              className="text-[0.75rem] text-[#b6b9b7] border-[#b6b9b7] border-[1px] p-[0.25rem]"
            >
              1 Upper Case
            </p>
            <p
              id="lower"
              className="text-[0.75rem] text-[#b6b9b7] border-[#b6b9b7] border-[1px] p-[0.25rem]"
            >
              1 Lower Case
            </p>
            <p
              id="num"
              className="text-[0.75rem] text-[#b6b9b7] border-[#b6b9b7] border-[1px] p-[0.25rem]"
            >
              1 Number
            </p>
            <p
              id="char"
              className="text-[0.75rem] text-[#b6b9b7] border-[#b6b9b7] border-[1px] p-[0.25rem]"
            >
              1 Special Character (@ & # $ %)
            </p>
          </div>

          {/* Confirm Password  */}
          <div className="mb-[2rem]">
            <div className="relative mt-[1.8rem]">
              <input
                className="outline-none border-b-[1px] bg-[#fff] border-[#24B24B] py-[0.3rem] px-[1rem] w-full"
                type={isVisibleConfirmPassword ? "text" : "password"}
                placeholder="Enter Confirm Password"
                name="Enter Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {isVisibleConfirmPassword ? (
                <Icon
                  onClick={() =>
                    setIsVisibleConfirmPassword(!isVisibleConfirmPassword)
                  }
                  className="absolute text-2xl top-2 right-2 text-[#B1B1B1] cursor-pointer"
                  icon="tabler:eye-off"
                />
              ) : (
                <Icon
                  onClick={() =>
                    setIsVisibleConfirmPassword(!isVisibleConfirmPassword)
                  }
                  className="absolute text-2xl top-2 right-2 text-[#B1B1B1] cursor-pointer"
                  icon="mi:eye"
                />
              )}
            </div>
            {/* Display Error  */}
            {!confirmPassword && showError && (
              <p className="text-red-500 text-[14px]">
                Confirm password is required*
              </p>
            )}
            {password &&
              confirmPassword &&
              showError &&
              password !== confirmPassword && (
                <p className="text-red-500 text-[14px] text-center mt-[0.5rem]">
                  Password doesn't match!
                </p>
              )}
          </div>

          <div className="flex flex-row justify-center">
            <button
              type="submit"
              className="w-full h-[60px] flex justify-center items-center text-white text-[1.1rem] rounded-md px-[1.5rem] py-[0.8rem] bg-primary font-bold"
              disabled={isLoading}
            >
              {isLoading ? (
                <LoadingButtonState />
              ) : (
                <span>Update Password</span>
              )}
            </button>
          </div>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
}
