import DatePicker from "react-datepicker";
import { Icon } from '@iconify/react';
import { useState } from "react";
export default function Header() {
  const [startDate, setStartDate] = useState("");
  return (
    <header className='flex xs:flex-row flex-col justify-between items-center'>
          <h3 className='text-[22px] font-bold text-[#1A3032]'>Notifications</h3>
          <div className='flex items-center gap-3'>
            <p className='font-medium text-[15px] text-[#1A3032]'>Mark all as read</p>
            <div className='flex items-center border-2 border-gray-200 p-1 rounded-lg bg-gray-200'>
         <DatePicker className='outline-none w-[90px] bg-gray-200' selected={startDate} placeholderText='Date' onChange={(date) => setStartDate(date)} />
         <Icon icon="healthicons:calendar" className='w-[22px] h-[22px] text-gray-600'/>
         </div>
          </div>
      </header>
  )
}
