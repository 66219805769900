import WalletHeader from "./WalletHeader";
import WalletCard from "./WalletCard";
import WalletList from "./WalletList";

export default function WalletsWrapper() {
  return (
    <div className="pt-[2.5rem] bg-white">
      <WalletHeader></WalletHeader>
      <WalletCard></WalletCard>
      <WalletList></WalletList>
    </div>
  );
}
