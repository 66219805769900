import { Icon } from "@iconify/react";
import strikeLogo from "../../assets/strike-logo.svg";
import { Link, useLocation, NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../store/AuthContext";
import './agentStyle.css';


const AgentSideBar = ({ isAdminSidebarOpen, setIsAdminSidebarOpen }) => {
  const path = useLocation().pathname.split("/")[2];


  //   const { logout } = useAuth();
  const navigate = useNavigate();

  const { logout } = useAuth();
  // const handleLogout = async () => {
  //   try {
  //     await logout();
  //     navigate("/adminLogin", { replace: true });
  //   } catch (error) {
  //     console.log(error);
  //     toast.error("Failed to logout.", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   }
  // };

  return (
    <div
      className="bg-[#F7FAFC] text-primary relative lg:sticky h-screen overflow-y-auto lg:top-0 lg:flex lg:flex-col lg:justify-between pb-[2.5rem] custom-container lg:pt-[4.5rem]"
    >
      <div>
        <div className="flex flex-col justify-center items-center sticky top-0 my-[1rem] bg-[#F7FAFC]  z-10">
          <div
            className="p-[0.2rem] absolute top-2 right-2 lg:hidden cursor-pointer bg-primary 
            rounded-[6px]"
            onClick={() => setIsAdminSidebarOpen(false)}
          >
            <Icon
              icon="material-symbols:close-rounded"
              className="text-[1.2rem] text-white"
            />
          </div>

          <Link to="/" className="w-full flex flex-col items-center py-[1rem] lg:hidden mt-[1rem]">
            <img src={strikeLogo} alt="skygoalLogo" className="" />
          </Link>
        </div>

        <div className="flex flex-col gap-[3rem] px-[1rem] py-[1rem]">
          <div className="flex flex-col gap-[1rem]">
            <NavLink to="/" end>
              {({ isActive, isPending }) => (
                <div
                  onClick={() => setIsAdminSidebarOpen(false)}
                  className={`flex gap-[0.9rem] items-center rounded-[12px] py-[0.8rem] 
                  px-[0.8rem]
                  ${isActive
                      ? "text-primary font-bold"
                      : "text-[#1A202C]"
                    } cursor-pointer`}
                >
                  <Icon icon="ic:round-dashboard" className="text-[1.5rem]" />
                  <h4 className="text-[1rem]">Dashboard</h4>
                </div>
              )}
            </NavLink>

            <NavLink to="/fund-requests">
              {({ isActive, isPending }) => (
                <div
                  onClick={() => setIsAdminSidebarOpen(false)}
                  className={`flex gap-[0.9rem] items-center rounded-[12px] py-[0.8rem] px-[0.8rem]
                  ${isActive
                      ? "text-primary font-bold"
                      : "text-[#1A202C]"
                    } cursor-pointer`}
                >
                  <Icon icon="pepicons-pop:dollar-circle-filled" className="text-[1.5rem]" />
                  <h4 className="text-[1rem]">Fund Requests</h4>
                </div>
              )}
            </NavLink>

            <NavLink to="/wallets">
              {({ isActive, isPending }) => (
                <div
                  onClick={() => setIsAdminSidebarOpen(false)}
                  className={`flex gap-[0.9rem] items-center rounded-[12px] py-[0.8rem] px-[0.8rem] 
                  ${isActive
                      ? "text-primary font-bold"
                      : "text-[#1A202C]"
                    } cursor-pointer`}
                >
                  <Icon
                    icon="solar:wallet-bold"
                    className="text-[1.5rem]"
                  />
                  <h4 className="text-[1rem]">Wallet</h4>
                </div>
              )}
            </NavLink>
{/* 
            <NavLink to="/notifications">
              {({ isActive, isPending }) => (
                <div
                  onClick={() => setIsAdminSidebarOpen(false)}
                  className={`flex gap-[0.9rem] items-center rounded-[12px] py-[0.8rem] px-[0.8rem]
                  ${isActive
                      ? "text-primary font-bold"
                      : "text-[#1A202C]"
                    } cursor-pointer`}
                >
                  <Icon icon="mdi:bell-notification" className="text-[1.5rem]" />
                  <h4 className="text-[1rem]">Notifications</h4>
                </div>
              )}
            </NavLink> */}

            <NavLink to="/settings">
              {({ isActive, isPending }) => (
                <div
                  onClick={() => setIsAdminSidebarOpen(false)}
                  className={`flex gap-[0.9rem] items-center rounded-[12px] py-[0.8rem] px-[0.8rem]
                  ${isActive
                      ? "text-primary font-bold"
                      : "text-[#1A202C]"
                    } cursor-pointer`}
                >
                  <Icon
                    icon="ic:settings"
                    className="text-[1.5rem]"
                  />
                  <h4 className="text-[1rem]">Settings</h4>
                </div>
              )}
            </NavLink>
            <button onClick={() => logout()}
              className="flex gap-[0.9rem] items-center justify-start w-full text-[#1A202C] hover:text-[#BE0A23] focus:text-[#BE0A23] rounded-[8px] py-[0.8rem] px-[0.8rem] mt-[8rem]"
            //   onClick={handleLogout}
            >
              <Icon icon="solar:logout-2-bold" className="text-[1.5rem]" />
              <h4 className="text-[1rem]" type="button">
                Log Out
              </h4>
            </button>
          </div>
          {/* <hr className="border-[#DFDFDF]" /> */}
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AgentSideBar;