import React, { useState } from "react";
import { IconButton } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import { toast } from "react-toastify";
import { ThreeDots } from 'react-loader-spinner'

export default function RejectRequestModal({ id, setShowRejectModal, setIsRefetch }) {
  const [showError, setShowError] = useState("");
  const [loaderBtn, setLoaderBtn] = useState(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [reason, setReason] = useState("");
  const { getAccessToken } = useAuth();

  const submitHandler = async () => {
    if (!reason.trim()) {
      setShowError(true);
      return;
    }
    setDisableSubmitBtn(true);
    setLoaderBtn(true);
    const token = await getAccessToken();
    try {
      const response = await fetch(`${APIurls.updateFundRequestStatus}/${id}?status=REJECTED`, {
        method: "PUT",
        body: JSON.stringify({
          "reason": reason,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
      );
      if (!response.ok) {
        if (response.statusCode === 400) {
          const errorResult = await response.json();
          toast.error(errorResult);
          throw new Error(errorResult);
        } else {
          toast.error("Failed to Reject Request");
          throw new Error("Failed to Reject Request");
        }
      }
      const result = await response.json();
      toast.success("Request Rejected successfully");
      setShowRejectModal(false);
      setIsRefetch(true);
      console.log("Request Rejected -->", result);
    } catch (error) {
      console.warn("Request Rejected Error -->", error);
    }
    setLoaderBtn(false);
    setDisableSubmitBtn(false);
  };

  return (
    <div className="flex items-center justify-center absolute bg-[#000000E6] h-full w-full inset-0 z-10">
      <div className="flex flex-col bg-white py-6 px-8 min-w-[550px] rounded-2xl">
        <div className="flex justify-end w-full">
          <IconButton
            onClick={() => setShowRejectModal(false)}
            className="shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-md hover:bg-[#FF0023] hover:text-white"
            size="sm"
          >
            <Icon icon="material-symbols:close" className="text-[1.5rem]" />
          </IconButton>
        </div>
        <div className="text-[#212529] flex flex-col gap-4">
          <h2 className='text-[1.3rem] font-bold'>
            Are you sure?
          </h2>
          <p className='mt-[-10px]'>
            You want to Reject User Fund Request.
          </p>
          <div className='flex flex-col gap-2'>
            <label htmlFor="description" className='font-semibold'>
              Reason for Rejecting
            </label>
            <textarea
              name=""
              id=""
              rows="5"
              placeholder='Enter description...'
              className='font-medium px-[1rem] py-[0.5rem] border border-gray-600 rounded-md resize-none'
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
            {showError && !reason.trim() && (
              <p className="text-sm text-[#E92215]">
                *This field is required.
              </p>
            )}
          </div>
          <div className="flex items-center justify-end">
            {
              loaderBtn ? (
                <ThreeDots
                  height="50"
                  width="50"
                  radius="9"
                  color="#24B24B"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                <button
                  disabled={disableSubmitBtn}
                  type="button"
                  className="text-center justify-center text-[0.8rem] sm:text-[18px] flex items-center max-xs:w-full gap-[0.3rem] xs:gap-[1rem] bg-[#FF0023] text-white py-[0.5rem] px-[2rem] xs:px-[3rem] font-semibold rounded-[8px] my-2"
                  onClick={submitHandler}
                >
                  Reject
                </button>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}


