import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Label } from 'recharts';
import { Icon } from '@iconify/react';
import { useAuth } from '../../../../store/AuthContext';
import { APIurls } from '../../../../api/apiConstant';
import { useQuery } from 'react-query';
import { useMemo } from 'react';


const renderLegend = (props) => {
  const { payload } = props;
  return (
    <ul className='flex justify-between items-center mt-[-50px]'>
      {
        payload.map((entry, index) => (
          <li key={`item-${index}`}>
            <div className='flex items-center gap-1'>
            <Icon icon="material-symbols-light:circle" className="text-[#25BF17] w-2 h-2"/>
            <p className='font-medium text-[12px]'>{entry.value}</p>
            </div>
            <h4 className='text-[18px] font-bold ml-3'>{entry?.payload?.percent?.toFixed(2)*100}%</h4>
          </li>
        ))
      }
    </ul>
  );
}


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, payload }) => {
  const {name} = payload;
  // console.log("Piechart=========>", name)
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="left" className='text-[10px]'>
      <>
      <tspan>{name}</tspan>
      <tspan x={x} dy="1.5em" className='text-[14px]'>
      {`${(percent * 100)?.toFixed(0)}%`}
      </tspan>
      </>
    </text>
  );
};



const COLORS = ['#25BF17', '#6CDE62'];

export default function TotalRequestPieChart() {
  const { agentDetails, getAccessToken } = useAuth();
  
  const fetchFundRequestData = async () => {
    const token = await getAccessToken()

    const response = await fetch(APIurls.fetchFundRequests, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error("Failed to Fetch Fund Requests");
    }
    const result = await response.json();

    console.log("Fund Requests...", result?.response?.fundRequest);
    return result?.response?.fundRequest?.reverse();
  };
  const { data: fundRequest, isLoading, refetch } = useQuery('fundRequestData', fetchFundRequestData);

  let completed = useMemo(() => (
    fundRequest?.filter((data) => data?.status === "PROCESSED")
  ), [fundRequest])
  let pending = useMemo(() => (
    fundRequest?.filter((data) => data?.status === "SUBMITTED")
  ), [fundRequest])

  const data = [
    {name:'Approved', value: completed?.length},
    {name:'Pending', value: pending?.length},
  ];
  return (
    <div className='p-4 rounded-lg border-gray-300 border-[1px] md:max-w-[70%] w-[95%] mx-auto h-[400px]'>
    <header className='flex justify-between items-center pb-10'>
    <div className='flex items-center gap-2'>
      <Icon icon="material-symbols-light:circle" className="text-[#25BF17]"/>
      <h3 className='text-[20px] font-medium'>Total Requests</h3>
    </div>
    </header>
    
    {/* chart  */}
    <ResponsiveContainer className="mt-[-60px]" width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
            nameKey="name"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        <Legend verticalAlign="bottom" height={36} iconType='circle' content={renderLegend}/>
        <Label/>
        </PieChart>
      </ResponsiveContainer>
  </div>
  )
}
