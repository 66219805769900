import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

export default function WalletHeader() {
  return (
    <div className="flex justify-between items-center mx-8">
          <h3 className="text-[20px] font-medium mb-6">Wallet</h3>
         <Link to="deposit-request">
         <button className="bg-[#25BF17] rounded-[8px] px-[31px] py-[8px] text-white flex items-center gap-3">
            Deposit
            <Icon icon="akar-icons:arrow-right" />
          </button>
         </Link>
    </div>
  )
}
