import { createBrowserRouter, useNavigate } from "react-router-dom";
import AgentLayout from "../layouts/AgentLayout";
import AgentPanelDashboard from "../components/agentPanel/AgentPanelDashboard";
import AgentPanelWallet from "../components/agentPanel/AgentPanelWallet";
import AgentPanelFundRequest from "../components/agentPanel/AgentPanelFundRequest";
import WalletView from "../components/agentModule/Wallet/WalletView";
import FundRequestView from "../components/agentModule/FundRequests/FundRequestView";
import NotificationsWrapper from "../components/agentModule/Notifications/NotificationsWrapper";
import FundRequestsWrapper from "../components/agentModule/FundRequests/FundRequestsWrapper";
import WalletsWrapper from "../components/agentModule/Wallet/WalletsWrapper";
import Settings from "../components/agentModule/Settings/Settings";
import AgentLogin from "../pages/AgentLogin";
import { useAuth } from "../store/AuthContext";
import Welcome from "../pages/Welcome";
import { Navigate } from "react-router-dom"
import DepositRequest from "../components/agentModule/Wallet/DepositRequest";
import DepositDetails from "../components/agentModule/Wallet/DepositDetails";
import FundRequestList from "../components/agentModule/FundRequests/FundRequestList";
import TotalFunds from "../components/agentModule/FundRequests/TotalFunds";
import Profile from "../components/agentModule/Profile/Profile";
import SendOtpForm from "../pages/SendOtpForm";
import VerifyOtpForm from "../pages/VerifyOtpForm";
import ResetPasswordForm from "../pages/ResetPasswordForm";
import KYCPending from "../pages/KYCPending";
import Suspend from "../pages/Suspend";

const PrivateRoute = ({ component: Component }) => {
  const { currentUser, userRole, agentDetails } = useAuth();
  // console.log("User Role", userRole)
  // console.log("User Role", currentUser)
  return (

    //  <>
    //     {true && true ? (
    //        <Component />
    //     ) : (
    //        <Navigate to="/login" replace />
    //     )}
    //  </>
    <>
      {/* {currentUser && userRole?.role?.walletAgent ? (
        <Component />
      ) : (
        <Navigate to="/login" replace />
      )} */}

      {currentUser && userRole?.role?.walletAgent ? (
        agentDetails?.status === "ACTIVE" ? (
          <Component />
        ) : agentDetails?.status === "KYC_PENDING" ? (
          <Navigate to="/kyc-pending" replace />
        ) : (
          agentDetails?.status === "SUSPENDED" ? <Navigate to="/suspended" replace /> : ""
        )
      ) : (
        <Navigate to="/login" replace />
      )}
    </>

  );
};

const PublicRoute = ({ component: Component }) => {
  const { currentUser, userRole, agentDetails } = useAuth();

  console.log("Current" + currentUser, userRole)
  return (

    <>
      {/* {currentUser && userRole?.role?.walletAgent ? (
        <Navigate to="/" replace />
      ) : (
        <Component />
      )} */}
      {currentUser && userRole?.role?.walletAgent && agentDetails?.status === "ACTIVE" ? (
        <Navigate to="/" replace />
      ) : (
        <Component />
      )}
    </>
  );
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute component={AgentLayout} />,
    children: [
      {
        path: "",
        element: <PrivateRoute component={AgentPanelDashboard} />,
      },
      {
        path: "fund-requests",
        element: <PrivateRoute component={AgentPanelFundRequest} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={FundRequestsWrapper} />,
            children: [
              {
                path: "",
                element: <PrivateRoute component={FundRequestList} />,
              },
              {
                path: "total",
                element: <PrivateRoute component={TotalFunds} />,
              },
            ]
          },
          {
            path: "view/:id",
            element: <PrivateRoute component={FundRequestView} />,
          },
        ],
      },
      {
        path: "wallets",
        element: <PrivateRoute component={AgentPanelWallet} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={WalletsWrapper} />,
          },
          {
            path: "view/:id",
            element: <PrivateRoute component={WalletView} />,
          },
          {
            path: "deposit-request",
            element: <PrivateRoute component={DepositRequest} />,
          },
          {
            path: "deposit-details",
            element: <PrivateRoute component={DepositDetails} />,
          },
        ],
      },
      {
        path: "notifications",
        element: <PrivateRoute component={NotificationsWrapper} />,
      },
      {
        path: "settings",
        element: <PrivateRoute component={Settings} />,
      },
      {
        path: "profile",
        element: <PrivateRoute component={Profile} />,
      },
    ],
  },
  {
    path: "/login",
    element: <PublicRoute component={AgentLogin} />,
  },
  {
    path: "/forgot-password",
    element: <PublicRoute component={SendOtpForm} />,
  },
  {
    path: "/verify-otp",
    element: <PublicRoute component={VerifyOtpForm} />,
  },
  {
    path: "/reset-password",
    element: <PublicRoute component={ResetPasswordForm} />,
  },
  // {
  //   path: "/login",
  //   element: <PublicRoute component={KYCPending} />,
  // },
  {
    path: "/kyc-pending",
    element: <PublicRoute component={KYCPending} />,
  },
  {
    path: "/suspended",
    element: <PublicRoute component={Suspend} />,
  },
  // {
  //   path: "/",
  //   element: <Welcome />,
  // },
],
);
